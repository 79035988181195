<template>
  <section class="workflow-view">
    <div v-loading="loading">
      <div class="pageTopBar">
        <el-row type="flex" align="middle">
          <el-col :span="6">
            <div class="d-flex align-center">
              <el-popconfirm
                title="Are you sure to Exit?"
                @confirm="backToAllFormBuilders()"
                confirm-button-text="Yes"
                cancel-button-text="No"
              >
                <el-button
                  slot="reference"
                  class="back-btn type-2"
                  :style="
                    getIsMobile
                      ? 'margin-bottom:50px'
                      : 'margin-left: 4px; border-radius: 4px'
                  "
                >
                  <i class="el-icon-back"></i>
                </el-button>
              </el-popconfirm>
              <div class="icon-text">
                <h3 class="h3 f-weight-500 pl-15 uppercase form-heading">
                  {{ action }} Form builder
                </h3>
              </div>
            </div>
          </el-col>
          <el-col v-if="!formBuilderId" :span="12">
            <el-steps
              :active="activeStep"
              finish-status="success"
              align-center
              class="workflow-index"
            >
              <el-step
                title="Form Builder Details"
                @click.native="backToStepOne()"
              ></el-step>
              <el-step
                title="Build Form Builder"
                @click.native="backToStepTwo()"
              ></el-step>
              <el-step
                title="Form Builder Settings"
                @click.native="backToStepThree()"
              ></el-step>
            </el-steps>
          </el-col>
          <el-col v-else :span="12">
            <div class="steps-radio-group text-center">
              <div class="el-radio-group">
                <el-radio-group
                  @change="onChangeFormBuilderStep"
                  v-model="formBuilderEditStep"
                >
                  <el-radio-button
                    label="Form Builder Details"
                  ></el-radio-button>
                  <el-radio-button
                    label="Build Form Builder"
                    :disabled="!formBuilder.name"
                  ></el-radio-button>
                  <el-radio-button
                    label="Form Builder Settings"
                  ></el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button
              type="primary"
              class="float- save-btn"
              :disabled="disableSaveAndNextButton()"
              @click="goToNextStep"
              v-if="activeStep == 1"
              icon="el-icon-circle-check"
              style="font-family:var(--font-family)"
              >Save Steps</el-button
            >

            <el-button
              type="primary"
              class="float- save-btn"
              :disabled="disableSaveAndNextButton()"
              @click="goToNextStep"
              v-if="activeStep == 2"
              icon="el-icon-circle-check"
              >Save Form builder</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div>
        <el-form label-position="right">
          <div>
            <el-row v-if="activeStep == 0" type="flex" justify="center">
              <el-col :span="7">
                <!-- <el-scrollbar wrap-style="max-height: 80vh; padding: 20px;"> -->
                <div class="workflow-details">
                  <div class="title">
                    <el-form-item label="Name">
                      <el-input
                        placeholder="Form builder name"
                        type="text"
                        maxlength="70"
                        v-model="formBuilder.name"
                      >
                        <template slot="prepend">
                          <div class="icon-dropdown-selection">
                            <el-button
                              type="text"
                              @click="iconPickerdialogVisible = true"
                            >
                              <div class="d-flex align-center">
                                <span>
                                  <!-- <img v-if="templateIconPath === ''" src="@/assets/img/template-icons/block-portfolio.svg" alt="icons" width="40"> -->
                                  <icons
                                    :iconName="formbuilderIconPath"
                                  ></icons>
                                </span>
                                <i class="el-icon-circle-plus"></i>
                              </div>
                            </el-button>
                          </div>
                        </template>
                      </el-input>
                    </el-form-item>
                  </div>

                  <p class="error" v-if="errors && errors.name">
                    {{ errors.name }}
                  </p>
                  <el-form-item label="Logo">
                    <div
                      v-if="formBuilder.logo"
                      class="logo-edit"
                      id="logoBody"
                    >
                      <icons :iconName="'FormTemplates'"></icons>
                      <div class="edit-box">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit logo"
                          placement="top-start"
                        >
                          <el-link
                            icon="el-icon-edit"
                            :underline="false"
                            type="warning"
                            @click="openLogoModal"
                          ></el-link>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Delete logo"
                          placement="top-start"
                        >
                          <el-link
                            icon="el-icon-delete"
                            class="ml-1 mr-1"
                            :underline="false"
                            type="danger"
                            @click="formBuilder.logo = ''"
                          ></el-link>
                        </el-tooltip>
                      </div>
                    </div>
                    <el-button type="text" @click="openLogoModal" v-else
                      >Upload</el-button
                    >
                  </el-form-item>
                  <p class="error" v-if="errors && errors.logo">
                    {{ errors.logo }}
                  </p>
                  <el-form-item
                    label="Which type of user can fill out the form?"
                  >
                    <div>
                      <el-tooltip placement="top" effect="light">
                        <div slot="content">
                          <p>
                            <strong>Company Users:</strong> Company users are
                            individuals with administrative permissions within
                            the system (e.g., Admins).
                          </p>
                          <p>
                            <strong>Application Users:</strong> Application
                            users are individuals who can log in to the user
                            side of the application (e.g., Employees).
                          </p>
                          <p>
                            <strong>Anyone:</strong> Anyone refers to any person
                            or individual, without specifying a particular
                            person, whose data is collected using their email
                            address.
                          </p>
                          <p>
                            <strong>Anonymous:</strong> Anonymous refers to
                            someone whose identity is not known or disclosed.
                          </p>
                        </div>
                        <i class="el-icon-info"></i>
                      </el-tooltip>
                    </div>

                    <el-select
                      placeholder="Select user type"
                      v-model="formBuilder.form_builders_owner"
                      :filterable="true"
                      style="width: 300px"
                      @change="setFormbuilderData"
                    >
                      <el-option
                        v-for="(ownerType, index) of allOwnerTypes"
                        :key="index"
                        :label="ownerType.label"
                        :value="ownerType.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <p class="error" v-if="errors && errors.form_builders_owner">
                    {{ errors.form_builders_owner }}
                  </p>

                  <el-form-item
                    label="Who can fill out the form?"
                    v-if="formBuilder.form_builders_owner == 'USER'"
                  >
                    <el-select
                      placeholder="Select Users"
                      v-model="formBuilder.user_type"
                      :filterable="true"
                      :clearable="true"
                      multiple
                      collapse-tags
                      style="width: 270px"
                    >
                      <el-option
                        v-for="item in getUserTypeList"
                        :key="item._id"
                        :label="getLabel(item)"
                        :value="item._id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <p class="error" v-if="errors && errors.user_type">
                    {{ errors.user_type }}
                  </p>
                  <el-form-item
                    label="Who can fill out the form?"
                    v-if="formBuilder.form_builders_owner == 'ENTITY'"
                  >
                    <el-select
                      placeholder="Select Entity"
                      v-model="formBuilder.entity_id"
                      :filterable="true"
                      :clearable="true"
                      @change="setSettingsEntity"
                      style="width: 300px"
                    >
                      <el-option
                        v-for="(entity, index) of entitiesData.filter(
                          (e) =>
                            (e.entity_type == 'INDIVIDUAL' ||
                              e.entity_type == 'BUSINESS') &&
                            e.type !== 'STANDARD'
                        ) || []"
                        :key="index"
                        :label="entity.name"
                        :value="entity._id"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <p class="error" v-if="errors && errors.entity_id">
                    {{ errors.entity_id }}
                  </p>

                  <el-form-item
                    label="Filter selected users by?"
                    v-if="
                      formBuilder.form_builders_owner == 'ENTITY' &&
                      formBuilder.entity_id &&
                      formBuilder.settings &&
                      !formBuilder.settings.access_to_all
                    "
                  >
                    <el-select
                      placeholder="Select filter"
                      v-model="formBuilder.form_builder_filter"
                      :filterable="true"
                      :clearable="true"
                      style="width: 300px"
                    >
                      <el-option
                        v-for="(filter, index) of entityFilters"
                        :key="index + '_filter'"
                        :label="filter.filter_name"
                        :value="filter._id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <p class="error" v-if="errors && errors.form_builder_filter">
                    {{ errors.form_builder_filter }}
                  </p>
                  <el-form-item label="Who are the owners of this form?">
                    <el-select
                      multiple
                      collapse-tags
                      v-model="formBuilder.form_builder_owners"
                      placeholder="Select Users"
                      style="width: 300px"
                    >
                      <el-option
                        v-for="user in company_users"
                        :key="user.email"
                        :label="user.email"
                        :value="user._id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Groups">
                    <el-tooltip
                      content="Add New Group"
                      placement="top"
                      effect="light"
                    >
                      <i
                        class="el-icon-circle-plus custom-icon-style"
                        @click="addGroupPopUP"
                      ></i>
                    </el-tooltip>
                    <br />
                    <el-col
                      :span="20"
                      style="display: flex; align-items: center"
                    >
                      <el-select
                        v-model="formBuilder.groups"
                        placeholder="Select Group"
                        multiple
                        filterable
                        collapse-tags
                        style="width: 300px"
                      >
                        <el-option
                          v-for="(item, index) of getAllEntityGroupsData"
                          v-bind:key="index"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                  <div class="text-editor">
                    <label class="label">Description</label>

                    <!-- <div
                      class="outlined-icon-button"
                      style="
                        display: flex;
                        margin-left: 311px;
                        margin-top: -29px !important;
                      "
                    >
                      <icons :iconName="'TryUsingAI'"></icons>
                      <div style="padding: 2px" @click="OpenwithAi">
                        Try Using AI
                      </div>
                    </div> -->
                    <!-- <el-drawer
                      :visible.sync="showChatbot"
                      title="ChatGPT"
                      size="50%"
                      direction="rtl"
                      @close="CloseChatBot"
                    >
                      <chatgpt
                        @close-chatbot="CloseChatBot"
                        @copy-content="updateEditorContent"
                        :entity_fields="allMentionableFields"
                        :entity="(isFromBuilder = true)"
                        :isFromBuilder="true"
                        type="form_builder"
                      />
                    </el-drawer> -->

                    <TextEditor
                      v-if="!loading"
                      :editorData.sync="formBuilder.description"
                    ></TextEditor>
                  </div>

                  <p class="error" v-if="errors && errors.description">
                    {{ errors.description }}
                  </p>

                  <p class="error" v-if="errors && errors.critical_error">
                    {{ errors.critical_error }}
                  </p>

                  <div class="text-center">
                    <el-button
                      type="primary"
                      class="add-button"
                      :disabled="disableSaveAndNextButton()"
                      @click="goToNextStep"
                    >
                      Save and Next
                      <i class="el-icon-right"></i>
                    </el-button>
                  </div>
                </div>
                <!-- </el-scrollbar> -->
              </el-col>
            </el-row>
            <el-row v-if="activeStep == 1">
              <el-col :span="7">
                <div class="select-steps-form">
                  <el-button
                    class="outlined-icon-button try-with-ai"
                    @click="openDialog"
                    ><div style="display: flex; margin-left: 60px">
                      <icons :iconName="'TryUsingAI'"></icons>
                      <div style="padding-top: 6px; margin-left: 8px">
                        Create with AI
                      </div>
                    </div>
                  </el-button>
                  <div class="each-widget">
                    <div class="d-flex align-center space-between mb-1">
                      <h4 class="sub-heading">Templates</h4>
                      <div class="pl-1">
                        <el-input
                          placeholder="Search  by Template name "
                          size="mini"
                          prefix-icon="el-icon-search"
                          v-model="templateSearchString"
                          @input="searchTemplates"
                          clearable
                        ></el-input>
                      </div>
                    </div>
                    <div class="scrollable">
                      <el-scrollbar
                        wrap-style="max-height: 250px;"
                        style="padding-right: 7px"
                        v-if="templatesData && templatesData.length"
                      >
                        <el-button
                          class="list-item"
                          @click="clickTemplate(template, 'TEMPLATE')"
                          v-for="(template, index) of templatesData"
                          :key="index + '_template'"
                          :disabled="templateAlreadySeleted(template)"
                          style="font-family:var(--font-family)"
                        >
                          <div style="display: flex">
                            <icons
                              :iconName="
                                template.icon
                                  ? template.icon
                                  : 'Block-Portfolio'
                              "
                            ></icons>
                            <div class="text ml-2">{{ template.name }}</div>
                            <span
                              v-if="template.type == 'STANDARD'"
                              class="ml-1"
                            >
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="This is standard template. It can be used to create contacts."
                                placement="right"
                              >
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </span>
                          </div>
                        </el-button>
                      </el-scrollbar>
                      <span v-else>
                        {{
                          "No " +
                          `${
                            templateSearchString.length
                              ? "results"
                              : "templates"
                          }` +
                          " found"
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="each-widget">
                    <div class="d-flex align-center space-between mb-1">
                      <h4 class="sub-heading">Entities</h4>
                      <div class="pl-1">
                        <el-input
                          placeholder="Search by Entity name"
                          size="mini"
                          prefix-icon="el-icon-search"
                          v-model="entitySearchString"
                          @input="searchEntities"
                          clearable
                        ></el-input>
                      </div>
                    </div>
                    <div class="scrollable">
                      <el-scrollbar
                        wrap-style="max-height: 250px;"
                        style="padding-right: 7px"
                        v-if="entitiesData && entitiesData.length"
                      >
                        <el-button
                          class="list-item"
                          v-for="(entity, index) of entitiesData"
                          @click="clickEntity(entity, 'ENTITY')"
                          :key="index + '_entity'"
                          :disabled="entityAlreadySelected(entity)"
                          style="font-family:var(--font-family)"
                        >
                          <div style="display: flex">
                            <icons
                              :iconName="
                                entity.icon ? entity.icon : 'Block-Portfolio'
                              "
                            ></icons>
                            <div class="text ml-2">{{ entity.name }}</div>
                          </div>
                        </el-button>
                      </el-scrollbar>
                      <span v-else>
                        {{
                          "No " +
                          `${
                            entitySearchString.length ? "results" : "templates"
                          }` +
                          " found"
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="each-widget">
                    <h4 class="sub-heading">Components</h4>
                    <div class="scrollable">
                      <el-scrollbar
                        wrap-style="max-height: 250px;"
                        style="padding-right: 7px"
                      >
                        <el-button
                          class="list-item"
                          v-for="(asset, index) of assets"
                          :key="index + '_asset'"
                          @click="clickAssets(asset)"
                          :disabled="assetAlreadySeleted(asset)"
                          style="font-family:var(--font-family)"
                        >
                          <div style="display: flex">
                            <icons :iconName="'Templates'"></icons>
                            <div class="text ml-2">
                              {{ asset.title || asset.name }}
                            </div>
                          </div>
                        </el-button>
                      </el-scrollbar>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="17">
                <div class="workflow-steps">
                  <div
                    class="inner-box"
                    v-if="!formBuilder.steps || !formBuilder.steps.length"
                  >
                    <h4 class="text-center">Start Building Form Builder!</h4>
                    <p style="margin-left: 95px">
                      Select existing templates from the left panel or create
                      new ones using AI by clicking the '<b>Create with AI</b
                      >'button
                    </p>
                  </div>
                  <draggable
                    v-model="formBuilder.steps"
                    v-if="
                      formBuilder.steps && formBuilder.steps.length && refresh
                    "
                    :move="checkPosition"
                  >
                    <transition-group
                      tag="div"
                      name="flip-list"
                      id="field-group"
                    >
                      <div
                        class
                        v-for="(step, index) of formBuilder.steps"
                        :key="JSON.stringify(step)"
                      >
                        <div class="each-step">
                          <span class="arrow-down"></span>
                          <el-row type="flex" justify="left" align="middle">
                            <el-col :span="24">
                              <div class="float-right action-action">
                                <div
                                  class="documents-box"
                                  v-if="step.type == 'COMPANY_DOCUMENTS'"
                                >
                                  <el-tag>
                                    <i class="el-icon-document"></i>
                                    {{
                                      step.company_documents
                                        ? getTotalDocuments(
                                            step.company_documents
                                          )
                                        : ""
                                    }}
                                  </el-tag>
                                </div>
                                <div class="template-box">
                                  <el-button
                                    v-if="step.type == 'COMPANY_DOCUMENTS'"
                                    icon="el-icon-document"
                                    type="info"
                                    circle
                                    @click="updateStepDocumentData(step, index)"
                                    title="Documents"
                                    plain
                                  ></el-button>
                                  <el-button
                                    icon="el-icon-view"
                                    type="info"
                                    circle
                                    @click="showDetailedViewOfStep(step)"
                                    title="View"
                                    plain
                                  ></el-button>
                                  <el-button
                                    type="danger"
                                    class="delete-icon"
                                    icon="el-icon-delete"
                                    circle
                                    @click="removeExistedField(index)"
                                    title="Remove Field"
                                    plain
                                  ></el-button>
                                  <el-button
                                    icon="el-icon-setting"
                                    type="info"
                                    circle
                                    @click="updateStepData(step, index)"
                                    title="Settings"
                                    plain
                                  ></el-button>
                                </div>
                              </div>
                              <div
                                class="inner-wrap"
                                v-if="step.type == 'TEMPLATE'"
                              >
                                <div class="icon-template">
                                  <icons
                                    v-if="
                                      step.type == 'TEMPLATE' &&
                                      step.template &&
                                      templateIcons[step.template]
                                    "
                                    :iconName="templateIcons[step.template]"
                                  ></icons>
                                  <icons
                                    v-else-if="step.type == 'TEMPLATE'"
                                    :iconName="'Block-Portfolio'"
                                  ></icons>
                                  <icons
                                    v-else
                                    :iconName="templateIcons[step.template]"
                                  ></icons>
                                </div>
                                <div class="content">
                                  <span class="main-title"
                                    >Step {{ index + 1 }}</span
                                  >
                                  <span class="sub-title">
                                    {{
                                      step.settings &&
                                      step.settings.sender_title
                                        ? step.settings.sender_title
                                        : configureTitles.sender_title
                                        ? configureTitles.sender_title
                                        : step.type === "TEMPLATE"
                                        ? templateNames[step.template]
                                        : benefitNames[step.type]
                                    }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="inner-wrap"
                                v-else-if="step.type == 'ENTITY'"
                              >
                                <div class="icon-template">
                                  <icons
                                    v-if="
                                      step.type == 'ENTITY' &&
                                      step.entity &&
                                      entityIcons[step.entity]
                                    "
                                    :iconName="entityIcons[step.entity]"
                                  ></icons>
                                  <icons
                                    v-else-if="step.type == 'ENTITY'"
                                    :iconName="'Block-Portfolio'"
                                  ></icons>
                                  <icons
                                    v-else
                                    :iconName="benefitIcons[step.type]"
                                  ></icons>
                                </div>
                                <div class="content">
                                  <span class="main-title"
                                    >Step {{ index + 1 }}</span
                                  >
                                  <span class="sub-title">
                                    {{
                                      step.settings &&
                                      step.settings.sender_title
                                        ? step.settings.sender_title
                                        : configureTitles.sender_title
                                        ? configureTitles.sender_title
                                        : step.type === "ENTITY"
                                        ? entityNames[step.entity]
                                        : benefitNames[step.type]
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="inner-wrap" v-else>
                                <div class="icon-template">
                                  <img
                                    :src="
                                      require('@/assets/img/template-icons/' +
                                        benefitIcons[step.type])
                                    "
                                    alt="component"
                                    class="image"
                                  />
                                </div>
                                <div class="content">
                                  <span class="main-title"
                                    >Step {{ index + 1 }}</span
                                  >
                                  <span class="sub-title">
                                    {{ benefitNames[step.type] }}
                                  </span>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <p class="error" v-if="errors && errors.steps">
                    {{ errors.steps }}
                  </p>
                </div>
              </el-col>
            </el-row>

            <el-row v-if="activeStep == 2">
              <el-col :span="24">
                <el-tabs
                  v-model="activeMainTab"
                  type="border-card"
                  tab-position="left"
                  @tab-click="onApprovalTabChange"
                >
                  <el-tab-pane name="primary_settings">
                    <span slot="label">
                      <i class="el-icon-alarm-clock"></i> Primary Settings
                    </span>

                    <el-row>
                      <el-col :span="8">
                        <h4>Form builder availability</h4>
                        <el-radio-group
                          v-model="formBuilder.settings.is_time_bond"
                          size="mini"
                        >
                          <el-radio :label="false" border>All Time</el-radio>
                          <el-radio :label="true" border
                            >Limited Period</el-radio
                          >
                        </el-radio-group>
                      </el-col>

                      <el-col :span="8">
                        <h4 v-if="formBuilder.settings.is_time_bond">
                          Select Date & Time Range
                        </h4>
                        <el-form
                          :inline="true"
                          class="demo-form-inline"
                          v-if="formBuilder.settings.is_time_bond"
                        >
                          <!-- <el-form-item label="Start Date">
                          <el-date-picker
                            v-model="
                              formBuilder.settings.formbuilder_start_time
                            "
                           type="datetime"
                          placeholder="Select Date Time"
                          :format="'MM-dd-yyyy hh:mm:ss'" 
                          class="smaller-than"
                          prefix-icon="el-icon-date" 
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="End Date">
                          <el-date-picker
                            v-model="formBuilder.settings.formbuilder_end_time"
                            type="datetime"
                          placeholder="Select Date Time"
                          :format="'MM-dd-yyyy hh:mm:ss'" 
                          class="smaller-than"
                          prefix-icon="el-icon-date" 
                          >
                          </el-date-picker>
                        </el-form-item>
                          <el-form-item>-->

                          <el-date-picker
                            :format="'MM-dd-yyyy HH:mm:ss'"
                            v-model="
                              formBuilder.settings.formbuilder_date_range
                            "
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            popper-class="my-popover"
                            size="mini"
                          ></el-date-picker>
                        </el-form>
                      </el-col>
                    </el-row>
                    <el-row class="mt-3">
                      <el-checkbox
                        v-if="formBuilder.form_builders_owner !== 'ANONYMOUS'"
                        v-model="formBuilder.settings.secure_access"
                        >Secure access</el-checkbox
                      >
                      <el-checkbox
                        v-if="
                          formBuilder.form_builders_owner !== 'USER' &&
                          formBuilder.form_builders_owner !== 'ANONYMOUS'
                        "
                        v-model="formBuilder.settings.access_to_all"
                        >Allow new users to fill</el-checkbox
                      >
                      <el-checkbox
                        v-if="
                          selectedUserEntity &&
                          selectedUserEntity.name &&
                          formBuilder.settings.access_to_all &&
                          selectedUserEntity.entity_type === 'BUSINESS'
                        "
                        v-model="formBuilder.settings.select_from_existing"
                      >
                        Allow user to Select from existing
                        {{ selectedUserEntity.name }} data
                      </el-checkbox>
                    </el-row>
                  </el-tab-pane>
                  <!-- Only entity form builder has schedules -->
                  <el-tab-pane
                    v-if="formBuilder.form_builders_owner == 'ENTITY'"
                    name="schedule_settings"
                  >
                    <span slot="label">
                      <i class="el-icon-alarm-clock"></i> Schedule Settings
                    </span>
                    <el-form ref="form" label-width="200px">
                      <el-form
                        ref="form"
                        label-width="120px"
                        label-position="top"
                      >
                        <!-- <el-form-item label="Select Form builder Enity Data">
                        <el-radio-group
                          v-model="formBuilder.settings.is_allowed_to_all_users"
                        >
                          <el-radio :label="false"> All Entities </el-radio>
                          <el-radio :label="true">
                            Send workflow for Selected Entities
                          </el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <entities-emails-selection
                        v-if="formBuilder.settings.is_allowed_to_all_users"
                        :workflowDetails="formBuilder"
                        />-->

                        <el-form-item
                          label="User will Submit Form builder data"
                        >
                          <el-radio-group
                            v-model="formBuilder.settings.frequency"
                          >
                            <el-radio :label="false">Only One Time</el-radio>
                            <el-radio :label="true"
                              >Allow Multiple Submissions</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>

                        <el-checkbox
                          v-if="formBuilder.settings.frequency"
                          v-model="formBuilder.settings.schedules.status"
                          >Add Schedules for User</el-checkbox
                        >
                        <el-checkbox
                          v-if="
                            formBuilder.settings &&
                            formBuilder.settings.frequency &&
                            formBuilder.settings.schedules.status
                          "
                          v-model="formBuilder.settings.is_allowed_to_all_users"
                          class="ml-3"
                          >Add Schedules for all User</el-checkbox
                        >
                        <div style="display: flex">
                          <el-form-item
                            v-if="
                              formBuilder.settings &&
                              formBuilder.settings.frequency &&
                              formBuilder.settings.schedules.status
                            "
                            label="Select Time Zone"
                          >
                            <el-select
                              v-model="formBuilder.settings.schedules.time_zone"
                              placeholder="Select Timezone"
                              :filterable="true"
                              :clearable="true"
                              size="mini"
                            >
                              <el-option
                                v-for="(timezone, index) of time_zone"
                                :key="index"
                                :label="timezone"
                                :value="timezone"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            v-if="
                              formBuilder.settings &&
                              formBuilder.settings.frequency &&
                              formBuilder.settings.schedules.status &&
                              !formBuilder.settings.is_allowed_to_all_users
                            "
                            label="Select users to add schedules"
                            class="ml-2"
                          >
                            <el-select
                              v-model="
                                formBuilder.settings.allowed_entity_users
                              "
                              placeholder="Select user"
                              :filterable="true"
                              :clearable="true"
                              multiple
                              size="mini"
                              collapse-tags
                            >
                              <el-option
                                v-for="(user, index) of entityUsers"
                                :key="index + user._id"
                                :label="user.email"
                                :value="user._id + '#' + user.email"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            label="Expires after schedule sent"
                            v-if="
                              formBuilder.settings &&
                              formBuilder.settings.frequency &&
                              formBuilder.settings.schedules.status
                            "
                            class="ml-1"
                          >
                            <el-input-number
                              :controls="false"
                              v-model="
                                formBuilder.settings.schedules
                                  .expired_time_interval_value
                              "
                              size="mini"
                            ></el-input-number>

                            <el-select
                              v-model="
                                formBuilder.settings.schedules
                                  .expired_time_interval_type
                              "
                              placeholder="Select Type"
                              class="ml-1"
                              size="mini"
                            >
                              <el-option label="DAYS" value="DAYS"></el-option>
                              <el-option
                                label="WEEKS"
                                value="WEEKS"
                              ></el-option>
                              <el-option
                                label="MONTHS"
                                value="MONTHS"
                              ></el-option>
                              <el-option
                                label="YEARS"
                                value="YEARS"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <!-- <el-form-item
                      label="Schedule Start and End Date"
                      v-if="
                        formBuilder.settings.frequency &&
                        formBuilder.settings.schedules.status
                      "
                    >
                      <el-date-picker
                        v-model="formBuilder.settings.schedules.start_time"
                        type="datetime"
                        placeholder="Select date and time"
                      >
                      </el-date-picker>
                      <el-date-picker
                        v-model="formBuilder.settings.schedules.end_time"
                        type="datetime"
                        placeholder="Select date and time"
                      >
                      </el-date-picker>
                    </el-form-item>

                    <el-form-item
                      label="Schedule Interval"
                      v-if="
                        formBuilder.settings.frequency &&
                        formBuilder.settings.schedules.status
                      "
                    >
                      For Every
                      <el-input-number
                        :controls="false"
                        v-model="formBuilder.settings.schedules.interval_value"
                      ></el-input-number>

                      <el-select
                        v-model="formBuilder.settings.schedules.interval"
                        placeholder="Select Type"
                      >
                        <el-option label="MINUTES" value="MINUTES"></el-option>
                        <el-option label="HOURS" value="HOURS"></el-option>
                        <el-option label="DAYS" value="DAYS"></el-option>
                        <el-option label="WEEKS" value="WEEKS"></el-option>
                        <el-option label="MONTHS" value="MONTHS"></el-option>
                        <el-option label="YEARS" value="YEARS"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      label="Schedule Time in a Day"
                      v-if="
                        formBuilder.settings.schedules.interval != 'MINUTES' &&
                        formBuilder.settings.schedules.interval != 'HOURLY' &&
                        formBuilder.settings.frequency &&
                        formBuilder.settings.schedules.status
                      "
                    >
                      <el-time-select
                        v-model="formBuilder.settings.schedules.daily_start_time"
                        :picker-options="{
                          step: '1:00',
                          start: '0:00',
                          end: '23:00',
                        }"
                        placeholder="Select time"
                      >
                      </el-time-select>
                        </el-form-item>-->

                        <el-form-item
                          v-if="
                            formBuilder.settings &&
                            formBuilder.settings.frequency &&
                            formBuilder.settings.schedules.status
                          "
                        >
                          <el-row>
                            <el-col :span="7">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminutes="
                                        isminutesIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAllminutes
                                      "
                                      @change="handleCheckMinutesChange"
                                      >Minutes</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules.minutes
                                    "
                                    @change="handleCheckedMinuteChange"
                                  >
                                    <el-checkbox
                                      v-for="minute in minutes"
                                      :label="minute"
                                      :key="minute"
                                      >{{ minute }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                            <el-col :span="4">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminuteshours="
                                        ishoursIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAllhours
                                      "
                                      @change="handleCheckHoursChange"
                                      >Hours</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules.hours
                                    "
                                    @change="handleCheckedHourChange"
                                  >
                                    <el-checkbox
                                      v-for="hour in hours"
                                      :label="hour"
                                      :key="hour"
                                      >{{ hour }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                            <el-col :span="5">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminutesdays="
                                        isdaysIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAlldays
                                      "
                                      @change="handleCheckDaysChange"
                                      >Days</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules.days
                                    "
                                    @change="handleCheckedDayChange"
                                  >
                                    <el-checkbox
                                      v-for="day in days"
                                      :label="day"
                                      :key="day"
                                      >{{ day }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                            <el-col :span="3">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminutesweeks="
                                        isweeksIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAllweeks
                                      "
                                      @change="handleCheckWeeksChange"
                                      >Days of Week</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules
                                        .days_of_week
                                    "
                                    @change="handleCheckedWeekChange"
                                  >
                                    <el-checkbox
                                      v-for="week in weeks"
                                      :label="week"
                                      :key="week"
                                      >{{ week }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                            <el-col :span="3">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminutesmonths="
                                        ismonthsIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAllmonths
                                      "
                                      @change="handleCheckMonthsChange"
                                      >Months</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules.months
                                    "
                                    @change="handleCheckedMonthChange"
                                  >
                                    <el-checkbox
                                      v-for="month in months"
                                      :label="month"
                                      :key="month"
                                      >{{ month }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                            <el-col :span="2">
                              <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                  <span>
                                    <el-checkbox
                                      :indeterminateminutesyears="
                                        isyearsIndeterminate
                                      "
                                      v-model="
                                        formBuilder.settings.schedules
                                          .checkAllyears
                                      "
                                      @change="handleCheckYearsChange"
                                      >Years</el-checkbox
                                    >
                                  </span>
                                </div>
                                <div class="text item">
                                  <el-checkbox-group
                                    v-model="
                                      formBuilder.settings.schedules.years
                                    "
                                    @change="handleCheckedYearChange"
                                  >
                                    <el-checkbox
                                      v-for="year in years"
                                      :label="year"
                                      :key="year"
                                      >{{ year }}</el-checkbox
                                    >
                                  </el-checkbox-group>
                                </div>
                              </el-card>
                            </el-col>
                          </el-row>
                        </el-form-item>
                      </el-form>
                    </el-form>
                  </el-tab-pane>

                  <el-tab-pane
                    v-if="formBuilder.form_builders_owner"
                    name="user_submission_settings"
                  >
                    <span slot="label">
                      <i class="el-icon-tickets"></i> User Submission Settings
                    </span>

                    <el-form
                      ref="form"
                      label-width="120px"
                      label-position="top"
                    >
                      <el-form-item label="User will Submit Form builder data">
                        <el-radio-group
                          v-model="formBuilder.settings.frequency"
                        >
                          <el-radio :label="false">Only One Time</el-radio>
                          <el-radio :label="true"
                            >Allow Multiple Submissions</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>

                      <el-form-item
                        label="User will submit multiple Form builder data"
                        v-if="formBuilder.settings.frequency"
                      >
                        <el-radio-group
                          v-model="formBuilder.settings.limit_responses"
                          :disable="formBuilder.settings.frequency"
                        >
                          <el-radio :label="false">No Limit</el-radio>
                          <el-radio :label="true"
                            >Max Submissions for Single User</el-radio
                          >
                        </el-radio-group>
                        <el-input-number
                          v-if="
                            formBuilder.settings.limit_responses &&
                            formBuilder.settings.frequency
                          "
                          type="number"
                          v-model="formBuilder.settings.no_responses_limited"
                        />
                      </el-form-item>

                      <el-form-item
                        label="User will view or edit his/her submitted responses"
                      >
                        <el-checkbox
                          v-model="formBuilder.settings.view_responses"
                          >Allow user to View</el-checkbox
                        >
                        <el-checkbox
                          v-model="formBuilder.settings.edit_responses"
                          v-if="formBuilder.settings.view_responses"
                          >Allow user to edit</el-checkbox
                        >
                      </el-form-item>

                      <el-form-item
                        label="Form builder Total Submissions"
                        v-if="formBuilder.settings.frequency"
                      >
                        <el-radio-group
                          v-model="formBuilder.settings.limit_total_submissions"
                        >
                          <el-radio :label="false">No Limit</el-radio>
                          <el-radio :label="true"
                            >Max Submissions allowed for Form builder</el-radio
                          >
                        </el-radio-group>
                        <el-input-number
                          v-if="formBuilder.settings.limit_total_submissions"
                          type="number"
                          v-model="
                            formBuilder.settings.no_total_submissions_limited
                          "
                        />
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>

                  <el-tab-pane name="success_pages_redirects">
                    <span slot="label">
                      <i class="el-icon-finished"></i> Success Pages & Redirects
                    </span>

                    <el-form
                      ref="form"
                      label-width="120px"
                      label-position="top"
                    >
                      <el-form-item label="Success Page">
                        <el-checkbox
                          v-model="
                            formBuilder.settings.success_and_redirect_page
                              .notify_reciever
                          "
                          >Notify Reciever</el-checkbox
                        >
                        <el-checkbox
                          v-model="
                            formBuilder.settings.success_and_redirect_page
                              .notify_sender
                          "
                          >Notify Owners</el-checkbox
                        >
                        <div>
                          <el-select
                            v-if="
                              formBuilder.settings.success_and_redirect_page
                                .notify_sender
                            "
                            multiple
                            collapse-tags
                            v-model="
                              formBuilder.settings.success_and_redirect_page
                                .mail_recievers
                            "
                            style="width: 350px"
                            placeholder="Select E-mail Recievers"
                          >
                            <el-option
                              v-for="user of company_users.filter((e) =>
                                formBuilder.form_builder_owners.includes(e._id)
                              )"
                              :key="user.email"
                              :label="user.email"
                              :value="user._id"
                            ></el-option>
                          </el-select>
                        </div>
                        <el-switch
                          v-model="
                            formBuilder.settings.success_and_redirect_page
                              .redirect_to
                          "
                          inactive-text="Thankyou Page"
                          active-text="Redirects to Page"
                        ></el-switch>
                      </el-form-item>

                      <el-form-item
                        label="Redirect URL"
                        v-if="
                          formBuilder.settings.success_and_redirect_page
                            .redirect_to
                        "
                      >
                        <el-input
                          v-model="
                            formBuilder.settings.success_and_redirect_page
                              .redirect_to_url
                          "
                        />
                      </el-form-item>

                      <div
                        class="text-editor"
                        v-if="
                          !formBuilder.settings.success_and_redirect_page
                            .redirect_to
                        "
                      >
                        <label class="label">Thank you page text</label>
                        <TextEditor
                          :editorData.sync="
                            formBuilder.settings.success_and_redirect_page
                              .thankyou_page
                          "
                        ></TextEditor>
                      </div>
                    </el-form>
                  </el-tab-pane>

                  <el-tab-pane
                    v-if="this.formBuilder.settings.reminder_settings"
                    name="reminder_settings"
                  >
                    <span slot="label">
                      <i class="el-icon-bell"></i> Reminder Settings
                    </span>

                    <el-form>
                      <el-form-item label="Send reminders">
                        <el-checkbox
                          v-model="
                            formBuilder.settings.reminder_settings.status
                          "
                        ></el-checkbox>
                      </el-form-item>

                      <!-- <el-form-item
                      label="Schedule start and end date"
                      v-if="formBuilder.settings.reminder_settings.status"
                    >
                      <el-date-picker
                        v-model="
                          formBuilder.settings.reminder_settings
                            .reminder_start_time
                        "
                        type="datetime"
                        placeholder="Select date and time"
                      >
                      </el-date-picker>
                      <el-date-picker
                        v-model="
                          formBuilder.settings.reminder_settings
                            .reminder_end_time
                        "
                        type="datetime"
                        placeholder="Select date and time"
                      >
                      </el-date-picker>
                      </el-form-item>-->
                      <el-form-item
                        label="Send first reminder"
                        v-if="formBuilder.settings.reminder_settings.status"
                      >
                        after schedule sent
                        <el-input-number
                          :controls="false"
                          v-model="
                            formBuilder.settings.reminder_settings
                              .first_reminder_interval_value
                          "
                          size="mini"
                        ></el-input-number>

                        <el-select
                          v-model="
                            formBuilder.settings.reminder_settings
                              .first_reminder_interval_type
                          "
                          placeholder="Select Type"
                          class="ml-1"
                          size="mini"
                        >
                          <el-option
                            label="MINUTES"
                            value="MINUTES"
                          ></el-option>
                          <el-option label="HOURS" value="HOURS"></el-option>
                          <el-option label="DAYS" value="DAYS"></el-option>
                          <el-option label="WEEKS" value="WEEKS"></el-option>
                          <el-option label="MONTHS" value="MONTHS"></el-option>
                          <el-option label="YEARS" value="YEARS"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="Repeat Reminder"
                        v-if="formBuilder.settings.reminder_settings.status"
                      >
                        for every
                        <el-input-number
                          :controls="false"
                          v-model="
                            formBuilder.settings.reminder_settings
                              .reminder_interval_value
                          "
                          size="mini"
                        ></el-input-number>

                        <el-select
                          v-model="
                            formBuilder.settings.reminder_settings
                              .reminder_interval_type
                          "
                          placeholder="Select Type"
                          class="ml-1"
                          size="mini"
                        >
                          <el-option
                            label="MINUTES"
                            value="MINUTES"
                          ></el-option>
                          <el-option label="HOURS" value="HOURS"></el-option>
                          <el-option label="DAYS" value="DAYS"></el-option>
                          <el-option label="WEEKS" value="WEEKS"></el-option>
                          <el-option label="MONTHS" value="MONTHS"></el-option>
                          <el-option
                            label="YEARS"
                            value="YEARS"
                          ></el-option> </el-select
                        >until completed or expires
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane
                    name="receiver_email_customization"
                    v-if="formBuilder.form_builders_owner !== 'ANONYMOUS'"
                  >
                    <span slot="label">
                      <i class="el-icon-chat-line-square"></i> Receiver email
                      customization
                    </span>
                    Subject
                    <el-input
                      v-model="formBuilder.receiver_mail_template.subject"
                      placeholder="Enter subject"
                      class="mb-1"
                    ></el-input
                    >Custom message
                    <ckeditor
                      v-model="formBuilder.receiver_mail_template.body"
                      :config="editorConfig"
                    ></ckeditor>
                    <div
                      @click="chooseFromParentEmailTemplatesReceiver"
                      style="
                        cursor: pointer;
                        color: #409eff;
                        margin-left: 200px;
                        margin-top: 10px;
                      "
                    >
                      <span v-if="!formBuilder.receiver_mail_template.name"
                        >Choose Parent Template(Header & Footer)</span
                      >
                      <span v-else
                        >Change Parent Template (Header & Footer)</span
                      >
                    </div>
                    <div
                      v-if="formBuilder.receiver_mail_template.name"
                      class="mb-2"
                      style="margin-left: 200px"
                    >
                      Selected Parent :
                      <span>{{ formBuilder.receiver_mail_template.name }}</span>
                      <span>
                        <i
                          style="cursor: pointer"
                          @click="resetParentReceiver"
                          class="el-icon-error"
                        ></i>
                      </span>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane name="approval_settings">
                    <span slot="label">
                      <i class="el-icon-circle-check"></i> Approval Settings
                    </span>
                    <el-row>
                      <el-checkbox
                        @change="addInitialUsers"
                        v-model="formBuilder.is_approval_form"
                        >Is approval form?</el-checkbox
                      >
                      <el-checkbox
                        v-if="
                          formBuilder.is_approval_form &&
                          formBuilder.approval_users &&
                          formBuilder.approval_users.length > 1 &&
                          !formBuilder.approve_all_user
                        "
                        v-model="formBuilder.priority_based"
                      >
                        Approvals by priority
                        <el-popover
                          placement="top-start"
                          trigger="hover"
                          content="Last user has high priority"
                        >
                          <i class="el-icon-info" slot="reference"></i>
                        </el-popover>
                      </el-checkbox>
                    </el-row>
                    <div
                      v-if="formBuilder.is_approval_form"
                      class="all-template-container"
                    >
                      <el-tabs
                        v-model="activeApprovalTab"
                        @tab-click="onApprovalTabChange"
                      >
                        <el-tab-pane label="Users" name="users">
                          <el-row
                            v-for="(user, i) in formBuilder.approval_users"
                            :key="'user_' + i"
                            class="mt-1"
                          >
                            <el-col :span="3">
                              <el-select
                                v-model="user.user_type"
                                size="mini"
                                clearable
                                placeholder="Select user type"
                              >
                                <el-option
                                  v-for="userType in userTypes"
                                  :key="userType.value"
                                  :label="userType.label"
                                  :value="userType.value"
                                ></el-option>
                              </el-select>
                            </el-col>
                            <template v-if="user.user_type == 'COMPANY'">
                              <el-col :span="3">
                                <el-select
                                  v-model="user.company_user_type"
                                  clearable
                                  size="mini"
                                  @change="
                                    (value) => updateCompanyUsers(value, user)
                                  "
                                  placeholder="Select company user type"
                                  class="ml-1"
                                >
                                  <el-option
                                    v-for="item in getUserTypeList"
                                    :key="item._id"
                                    :label="getLabel(item)"
                                    :value="item._id"
                                  ></el-option>
                                </el-select>
                              </el-col>
                              <el-col :span="4">
                                <el-select
                                  v-if="user.company_user_type"
                                  v-model="user.preferred_company_users"
                                  clearable
                                  size="mini"
                                  multiple
                                  placeholder="Select preferred user"
                                  class="ml-1"
                                  default-first-option
                                >
                                  <el-option
                                    v-for="item in getCompanyUsersByType(
                                      user.company_user_type
                                    )"
                                    :key="item._id + '_company_user'"
                                    :label="item.email"
                                    :value="item.email"
                                    >{{ item.email }}</el-option
                                  >
                                </el-select>
                              </el-col>
                              <el-col :span="3">
                                <el-checkbox
                                  class="ml-1"
                                  v-if="
                                    getCompanyUsersByType(
                                      user.company_user_type
                                    ).length > 1
                                  "
                                  v-model="user.any_user_can_approve"
                                  >Any user can approve</el-checkbox
                                >
                              </el-col>
                            </template>
                            <template
                              v-else-if="user.user_type == 'APPLICATION'"
                            >
                              <el-col :span="3">
                                <el-select
                                  v-model="user.application_user_type"
                                  clearable
                                  size="mini"
                                  @change="updateApplicationUserType(user)"
                                  placeholder="Select application user type"
                                  class="ml-1"
                                >
                                  <el-option
                                    v-for="(
                                      type, index
                                    ) in getAllContactTypes.data ||
                                    getAllContactTypes"
                                    :key="index + 1"
                                    :value="type._id"
                                    :label="type.name"
                                    >{{ type.name }}</el-option
                                  >
                                </el-select>
                              </el-col>
                              <el-col :span="4">
                                <div
                                  class="ml-1"
                                  v-if="user.application_user_type"
                                >
                                  <el-select
                                    v-model="user.preferred_application_users"
                                    clearable
                                    multiple
                                    size="mini"
                                    placeholder="Select preferred user"
                                    :disabled="user.relational_user"
                                  >
                                    <el-option
                                      v-for="(
                                        contact, index
                                      ) in getUserTypeContacts(
                                        user.application_user_type
                                      )"
                                      :key="index + '_application_user_mail'"
                                      :value="contact.email"
                                      :label="contact.email"
                                      >{{ contact.email }}</el-option
                                    >
                                  </el-select>
                                  <el-checkbox
                                    v-if="
                                      checkIsRelational(
                                        user.application_user_type
                                      )
                                    "
                                    :disabled="
                                      user.preferred_application_users &&
                                      user.preferred_application_users.length
                                        ? true
                                        : false
                                    "
                                    v-model="user.relational_user"
                                    >User relational data</el-checkbox
                                  >
                                </div>
                              </el-col>
                              <el-col :span="3" class="mr-1">
                                <el-checkbox
                                  class="ml-1"
                                  v-if="
                                    getUserTypeContacts(
                                      user.application_user_type
                                    ).length > 1 && !user.relational_user
                                  "
                                  v-model="user.any_user_can_approve"
                                  >Any user can approve</el-checkbox
                                >
                              </el-col>
                            </template>
                            <template
                              v-else-if="user.user_type == 'LINKEDFIELD'"
                            >
                              <el-col :span="3">
                                <el-select
                                  v-model="user.approval_linked_field"
                                  clearable
                                  size="mini"
                                  placeholder="Select user data field"
                                  class="ml-1"
                                >
                                  <el-option
                                    v-for="(option, index) of getAllFields"
                                    :key="index"
                                    :value="option.key"
                                    :label="option.label"
                                    :disabled="
                                      checkDuplicate(
                                        i,
                                        option.key,
                                        'approval_linked_field'
                                      )
                                    "
                                    >{{ option.label }}</el-option
                                  >
                                </el-select>
                              </el-col>
                            </template>
                            <template>
                              <!-- <el-col :span="2" style="margin-left: 5px !important">
                                <el-checkbox v-model="user.has_edit">
                                  can edit?
                                </el-checkbox>
                              </el-col>-->
                              <el-col
                                :span="2"
                                style="margin-left: 40px !important"
                              >
                                <el-link
                                  icon="el-icon-delete"
                                  :underline="false"
                                  type="danger"
                                  @click="removeUser(i)"
                                ></el-link>
                              </el-col>
                            </template>
                          </el-row>
                          <el-row>
                            <el-button
                              class="mt-1"
                              type="primary"
                              plain
                              size="mini"
                              icon="el-icon-plus"
                              @click="addApprovers"
                              >Add Approvers</el-button
                            >
                            <el-checkbox
                              class="ml-1"
                              v-if="
                                formBuilder.approval_users.length > 1 &&
                                !formBuilder.priority_based
                              "
                              v-model="formBuilder.approve_all_user"
                              >All users needs to approve</el-checkbox
                            >
                          </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Mail template" name="mail">
                          Subject
                          <el-input
                            v-model="formBuilder.approval_mail_template.subject"
                            placeholder="Enter subject"
                          ></el-input
                          >Custom message
                          <ckeditor
                            v-model="formBuilder.approval_mail_template.body"
                            :config="editorConfig1"
                          ></ckeditor>
                          <div
                            @click="chooseFromParentEmailTemplates"
                            style="
                              cursor: pointer;
                              color: #409eff;
                              margin-left: 200px;
                            "
                          >
                            <span
                              v-if="!formBuilder.approval_mail_template.name"
                              >Choose Parent Template(Header & Footer)</span
                            >
                            <span v-else
                              >Change Parent Template (Header & Footer)</span
                            >
                          </div>
                          <div
                            v-if="formBuilder.approval_mail_template.name"
                            class="mb-2"
                            style="margin-left: 200px"
                          >
                            Selected Parent :
                            <span>
                              {{ formBuilder.approval_mail_template.name }}
                            </span>
                            <span>
                              <i
                                style="cursor: pointer"
                                @click="resetParent"
                                class="el-icon-error"
                              ></i>
                            </span>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Permissions" name="permissions" v-if="formBuilder.is_approval_form && formBuilder.approval_receiver_permissions">
                            Please specify the permissions to be granted to the receiver for viewing, editing, or deleting the submission after the form is approved:
                            <br>
                            <div class="mt-1">
                              <el-checkbox v-model="formBuilder.approval_receiver_permissions.view">VIEW</el-checkbox>
                              <el-checkbox v-model="formBuilder.approval_receiver_permissions.edit">EDIT</el-checkbox>
                              <el-checkbox v-model="formBuilder.approval_receiver_permissions.delete">DELETE</el-checkbox>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                          label="Fields to be shown for anonymous form"
                          v-if="
                            formBuilder &&
                            formBuilder.form_builders_owner == 'ANONYMOUS'
                          "
                        >
                          <el-select
                            placeholder="Primary fields"
                            v-model="formBuilder.primary_fields"
                            multiple
                            collapse-tags
                            class="mt-1"
                          >
                            <el-option
                              v-for="field in allFormbuilderFields"
                              :key="field.key"
                              :value="field.key"
                              :label="field.label"
                            ></el-option>
                          </el-select>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane name="expiration_settings">
                    <span slot="label">
                      <i class="el-icon-time"></i> Expiration Settings
                    </span>
                    <span>
                      When form response is in pending status, in how many
                      days/months/years will it be expired?
                    </span>
                    <el-row class="mt-1">
                      <el-select
                        placeholder="Expiry type"
                        v-model="formBuilder.expiration_settings.expiry_type"
                      >
                        <el-option value="YEARS" label="Year(s)"></el-option>
                        <el-option value="MONTHS" label="Month(s)"></el-option>
                        <el-option value="DAYS" label="Day(s)"></el-option>
                      </el-select>
                      <el-input-number
                        placeholder="Expiry Duration"
                        v-model="
                          formBuilder.expiration_settings.expiry_duration
                        "
                        :min="0"
                        class="ml-1"
                      ></el-input-number>
                    </el-row>
                  </el-tab-pane>
                  <el-tab-pane name="customization">
                    <span slot="label">
                      <i class="el-icon-finished"></i>Customization
                    </span>
                    <el-row>
                      <el-col :span="6">
                        Add button label
                        <el-input
                          v-model="formBuilder.customization.add_button_label"
                          placeholder="Enter label"
                        ></el-input
                        >Finish button label
                        <el-input
                          v-model="
                            formBuilder.customization.finish_button_label
                          "
                          placeholder="Enter label"
                        ></el-input>
                        <el-checkbox
                          v-model="
                            formBuilder.customization.show_link_to_be_copy
                          "
                          >Show link to be copy</el-checkbox
                        >
                        <el-checkbox
                          v-model="formBuilder.customization.show_detailed_link"
                          >Show detailed view</el-checkbox
                        >
                        <el-checkbox
                          v-model="
                            formBuilder.customization.show_initial_confirmation
                          "
                          >Show initial confirmation to continue</el-checkbox
                        >
                        <el-checkbox
                          v-model="
                            formBuilder.customization
                              .show_try_esigns_and_content
                          "
                          >Show try esigns button and content</el-checkbox
                        >
                        <el-checkbox
                          v-model="formBuilder.customization.show_title_bar"
                          >Show top bar and company logo</el-checkbox
                        >
                      </el-col>
                      <el-col :span="6"></el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>

      <!-- Select an Icon -->
      <dialog-component
        :title="'Select An Icon'"
        :visible="iconPickerdialogVisible"
        @before-close="closeIconModel"
        :isShowFooter="false"
        :containerWidth="'65%'"
        top="25px"
        width="1000px"
      >
        <div class="icons-component">
          <div class="iconslistScrollable">
            <icons-list @icon-data="getIconPath"></icons-list>
          </div>
        </div>
      </dialog-component>

      <!-- Title Settings -->
      <dialog-component
        :visible="configureSettings"
        :title="'Settings'"
        :containerWidth="'50%'"
        @before-close="doneTitleConfigure"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="resetConfigureSettings"
      >
        <div class="settings-formBuilder">
          <h3>Step settings</h3>
          <el-divider></el-divider>
          <el-form label-position="left">
            <el-row :gutter="30" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="Sender display name:">
                  <el-input
                    placeholder="Enter sender display name"
                    type="text"
                    maxlength="70"
                    v-model="configureTitles.sender_title"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="2">
                <el-form-item label="Receivers display name:">
                  <el-input
                    placeholder="Receivers display name"
                    type="text"
                    maxlength="70"
                    v-model="configureTitles.receiver_title"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="30"
              type="flex"
              v-if="
                formBuilder.form_builders_owner == 'ENTITY' &&
                seletedStepData.type == 'ENTITY' &&
                seletedStepData.entity == formBuilder.entity_id
              "
            >
              <el-col :span="24" style="display: flex">
                <el-checkbox
                  v-model="configureTitles.isLoginEntity"
                  style="margin-top: 5px"
                  @change="
                    checkForIndividual(configureTitles.selectedTemplates)
                  "
                  >Link this entity to reciever?</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row v-if="seletedStepData.type == 'ENTITY'">
              User can access following templates
              <el-select
                multiple
                collapse-tags
                v-model="configureTitles.selectedTemplates"
                placeholder="Select templates"
                size="mini"
                @change="(val) => checkForIndividual(val)"
              >
                <el-option
                  v-for="(temp, i) in entityTemplates"
                  :key="'eTemplates' + temp._id + i"
                  :value="temp._id"
                  :label="temp.name"
                  >{{ temp.name }}</el-option
                >
              </el-select>
            </el-row>
            <el-row
              type="flex"
              v-if="
                seletedStepData.type == 'TEMPLATE' &&
                checkIsInvolved &&
                !seletedStepData.standard_template
              "
            >
              <el-col :span="24" style="display: flex">
                <el-checkbox
                  v-model="configureTitles.is_repeatable"
                  style="margin-top: 3px"
                  >Use it as repeatable</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row
              type="flex"
              v-if="
                seletedStepData.type == 'TEMPLATE' &&
                checkIsInvolved &&
                seletedStepData.standard_template
              "
              style="margin-top: 10px"
            >
              <el-col :span="24">
                Create contact with
                <el-select
                  v-model="configureTitles.contact_entity"
                  size="mini"
                  placeholder="Select entity"
                  filterable
                  clearable
                  @change="updateEntityName"
                >
                  <el-option
                    v-for="(entity, index) in (
                      this.allEntitiesData || []
                    ).filter(
                      (e) =>
                        ['INDIVIDUAL'].indexOf(e.entity_type) !== -1 ||
                        (formBuilder.form_builders_owner == 'ENTITY' &&
                          e._id == formBuilder.entity_id)
                    )"
                    :key="'et_' + entity._id + index"
                    :label="entity.name"
                    :value="entity._id"
                    >{{ entity.name }}</el-option
                  > </el-select
                >type
              </el-col>
            </el-row>
            <!-- <el-divider></el-divider> -->
            <hr v-if="configureTitles.is_repeatable" />
            <el-row
              v-if="
                entityTables &&
                entityTables.length &&
                !configureTitles.is_repeatable &&
                checkIsInvolved &&
                seletedStepData.type == 'TEMPLATE'
              "
            >
              <el-col :span="24">
                <div style="display: flex; justify-content: space-between">
                  <p>Configure entity tables</p>
                  <el-link
                    type="primary"
                    :underline="false"
                    icon="el-icon-plus"
                    @click="addNewField"
                    >Add</el-link
                  >
                </div>
                <div v-if="!entityTablesRefresh">
                  <el-row :gutter="30">
                    <el-col :span="6">Field</el-col>
                    <el-col :span="6">Max</el-col>
                    <el-col :span="6">Min</el-col>
                    <el-col
                      :span="6"
                      v-if="formBuilder.form_builders_owner == 'ENTITY'"
                      >Apply relationship?</el-col
                    >
                  </el-row>
                  <el-row
                    :gutter="30"
                    v-for="(row, i) of entity_table_configuration"
                    :key="'table_' + i"
                    style="border-bottom: 1px solid #d0d0d0"
                    class="pb-1"
                  >
                    <el-col :span="6">
                      <el-select
                        v-model="row.selectField"
                        placeholder="Select field"
                        size="mini"
                      >
                        <el-option
                          v-for="(op, j) of entityTables"
                          :key="'op_' + j"
                          :label="op.label"
                          :value="op.key"
                          :disabled="
                            checkDuplicateField(i, op.key, 'selectField')
                          "
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        placeholder="Select field"
                        v-model="row.linked_field"
                        size="mini"
                        clearable
                      >
                        <el-option
                          v-for="(op, i) of getPrevStepFields"
                          :key="op.key + i"
                          :value="op.key"
                          :label="op.label"
                          >{{ op.label }}</el-option
                        >
                      </el-select>
                      <span class="ml-1 mr-1" v-if="!row.linked_field">OR</span>
                      <el-input-number
                        v-if="!row.linked_field"
                        v-model="row.max_records"
                        :min="1"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                    <el-col :span="6">
                      <el-select
                        placeholder="Select field"
                        v-model="row.linked_min_field"
                        size="mini"
                        clearable
                      >
                        <el-option
                          v-for="(op, i) of getPrevStepFields"
                          :key="op.key + i"
                          :value="op.key"
                          :label="op.label"
                          >{{ op.label }}</el-option
                        >
                      </el-select>
                      <span class="ml-1 mr-1" v-if="!row.linked_min_field"
                        >OR</span
                      >
                      <el-input-number
                        v-if="!row.linked_min_field"
                        v-model="row.min_records"
                        :min="1"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                    <el-col :span="6">
                      <el-checkbox
                        v-model="row.applyRelationship"
                        v-if="
                          entityTables &&
                          row.selectField &&
                          entityTables.find((e) => e.key == row.selectField) &&
                          checkRelationship(
                            entityTables.find((e) => e.key == row.selectField)
                              .entity_id
                          )
                        "
                      ></el-checkbox>
                      <el-link
                        icon="el-icon-delete"
                        :underline="false"
                        type="danger"
                        @click="removeNewField(i)"
                      ></el-link>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="configureTitles.is_repeatable" class="mt-1">
              <el-col :span="24">
                <label>Min no of records can be added</label>
                <el-select
                  placeholder="Select field"
                  v-model="configureTitles.linked_min_field"
                  size="mini"
                  clearable
                  class="ml-1"
                >
                  <el-option
                    v-for="(op, i) of getPrevStepFields"
                    :key="op.key + i"
                    :value="op.key"
                    :label="op.label"
                    >{{ op.label }}</el-option
                  >
                </el-select>
                <span class="ml-2 mr-2" v-if="!configureTitles.linked_min_field"
                  >OR</span
                >
                <el-input-number
                  v-if="!configureTitles.linked_min_field"
                  v-model="configureTitles.min_records"
                  :min="1"
                  size="mini"
                  class="ml-3"
                ></el-input-number>
              </el-col>
              <el-col :span="24" class="mt-1">
                <label>Max no of records can be added field</label>
                <el-select
                  placeholder="Select field"
                  v-model="configureTitles.linked_field"
                  size="mini"
                  clearable
                  class="ml-1"
                >
                  <el-option
                    v-for="(op, i) of getPrevStepFields"
                    :key="op.key + i"
                    :value="op.key"
                    :label="op.label"
                    >{{ op.label }}</el-option
                  >
                </el-select>
                <span class="ml-2 mr-2" v-if="!configureTitles.linked_field"
                  >OR</span
                >
                <el-input-number
                  v-if="!configureTitles.linked_field"
                  v-model="configureTitles.max_records"
                  :min="1"
                  size="mini"
                ></el-input-number>
              </el-col>
            </el-row>
            <el-row
              :gutter="30"
              type="flex"
              v-if="
                formBuilder.form_builders_owner == 'ENTITY' &&
                seletedStepData.type == 'ENTITY' &&
                checkRelationship(seletedStepData.entity)
              "
            >
              <el-col :span="24">
                <el-checkbox v-model="configureTitles.applyRelationship">
                  {{ getRelationshipText }}
                </el-checkbox>
              </el-col>
            </el-row>
            <!-- <el-row
              :gutter="30"
              type="flex"
              v-if="
                seletedStepData.type == 'TEMPLATE' &&
                checkTemplateExists(seletedStepData.template)
              "
            >
              <el-col :span="24">
                Owner entity also has same form template.
                <el-checkbox v-model="configureTitles.linkedToUserTemplate">
                  Are you want to link this template to owner entity template?
                </el-checkbox>
              </el-col>
            </el-row>-->
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            id="submitButton"
            type="primary"
            @click="doneTitleConfigure"
            :disabled="checkSubmitDisabled"
          >
            <i class="el-icon-finished"></i>
            <span>Submit</span>
          </el-button>
        </span>
      </dialog-component>

      <!-- Company Documents -->
      <el-dialog
        :visible.sync="configureDocumentSettings"
        title="Settings"
        :fullscreen="true"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="settings-selection-dialog"
        center
      >
        <div class="document-selection-box">
          <div class="mx-auto">
            <el-row
              v-if="configureCompanyDocumentsVisible"
              :gutter="30"
              type="flex"
              justify="center"
            >
              <el-col :span="16">
                <el-card shadow="never">
                  <h3 class="dialog-sub-title blue-2">
                    <i class="el-icon-document-add"></i>
                    <span class="pl-1">Choose Documents</span>
                  </h3>
                  <el-divider class="mb-05 mt-1"></el-divider>

                  <el-form v-model="searchDocumentFormData">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="Type">
                          <el-select
                            v-model="searchDocumentFormData.type"
                            @change="getCompanyDocuments"
                          >
                            <el-option
                              label="CUSTOM"
                              value="CUSTOM"
                            ></el-option>
                            <el-option
                              label="STATIC"
                              value="STATIC"
                            ></el-option>
                            <el-option
                              label="STANDARD"
                              value="STANDARD"
                            ></el-option>
                            <el-option
                              label="REQUESTED"
                              value="REQUESTED"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="Category"
                          v-if="searchDocumentFormData.type != 'STANDARD'"
                        >
                          <el-select
                            v-model="searchDocumentFormData.category_id"
                            @change="getCompanyDocuments"
                            clearable
                          >
                            <el-option
                              v-for="(category, index) of getAllCategories"
                              :key="index"
                              :label="category.name"
                              :value="category._id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item
                          label="Group"
                          v-if="searchDocumentFormData.type != 'STANDARD'"
                        >
                          <el-select
                            v-model="searchDocumentFormData.group_id"
                            @change="getCompanyDocuments"
                            clearable
                          >
                            <el-option
                              v-for="(group, index) of getAllGroups"
                              :key="index"
                              :label="group.name"
                              :value="group._id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div class="table-scrollable">
                    <el-table
                      class="vue-data-table-default"
                      v-if="
                        searchDocumentFormData.type != 'STANDARD' &&
                        getCompanyAllDocuments &&
                        getCompanyAllDocuments.data &&
                        getCompanyAllDocuments.data.length
                      "
                      :data="getCompanyAllDocuments.data"
                      height="calc(100vh - 320px)"
                    >
                      <el-table-column
                        label="Name"
                        prop="title"
                      ></el-table-column>
                      <el-table-column
                        label="Type"
                        prop="type"
                      ></el-table-column>
                      <el-table-column
                        label="Category"
                        prop="category.name"
                      ></el-table-column>
                      <el-table-column label="Groups">
                        <template slot-scope="scope">
                          {{ scope.row.groups.map((e) => e.name).join(", ") }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Add">
                        <template slot-scope="scope">
                          <el-button
                            size="mini"
                            :disabled="isDocExisted(scope.row, scope.row.type)"
                            @click="
                              addDocumentToStep(
                                scope.row,
                                scope.row.type.toLowerCase()
                              )
                            "
                          >
                            <i class="el-icon-circle-plus-outline"></i> Add
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table
                      class="vue-data-table-default"
                      v-if="
                        searchDocumentFormData.type == 'STANDARD' &&
                        standardDocumentsList &&
                        standardDocumentsList.length
                      "
                      :data="standardDocumentsList"
                    >
                      <el-table-column
                        label="Name"
                        prop="title"
                      ></el-table-column>
                      <el-table-column
                        label="Type"
                        prop="type"
                      ></el-table-column>
                      <el-table-column label="Add">
                        <template slot-scope="scope">
                          <el-button
                            size="mini"
                            type="primary"
                            :disabled="isDocExisted(scope.row, scope.row.type)"
                            @click="addDocumentToStep(scope.row, 'standard')"
                          >
                            <i class="el-icon-circle-plus-outline"></i> Add
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="7">
                <div class="submit-block right">
                  <el-button
                    id="submitButton"
                    type="success"
                    round
                    @click="doneCompanyDocuemnts()"
                  >
                    <i class="el-icon-finished"></i>
                    <span>Done</span>
                  </el-button>
                </div>
                <div class="selected-docx">
                  <h3 class="dialog-sub-title success">
                    <i class="el-icon-document-checked"></i>
                    <span class="pl-1">Selected Documents</span>
                  </h3>

                  <div
                    v-if="getTotalDocuments(seletedStepData.company_documents)"
                  >
                    <el-card
                      shadow="never"
                      class="scrollable-min"
                      v-if="
                        seletedStepData &&
                        seletedStepData.company_documents.static &&
                        seletedStepData.company_documents.static.length
                      "
                    >
                      <h4 class="blue-2 card-title">
                        <i class="el-icon-document"></i> Static Documents
                      </h4>
                      <el-divider class="mb-0 mt-0"></el-divider>
                      <div
                        class="each-document"
                        v-for="(doc, index) of seletedStepData.company_documents
                          .static"
                        :key="index"
                      >
                        <el-button
                          circle
                          class="float-right"
                          size="mini"
                          type="danger"
                          icon="el-icon-close"
                          @click="removeComapnyDocument('static', index)"
                        ></el-button>
                        <h4>{{ doc.title }}</h4>
                      </div>
                    </el-card>

                    <el-card
                      shadow="never"
                      class="scrollable-min"
                      v-if="
                        seletedStepData &&
                        seletedStepData.company_documents.requested &&
                        seletedStepData.company_documents.requested.length
                      "
                    >
                      <h4 class="blue-2 card-title">
                        <i class="el-icon-document"></i> Requested Documents
                      </h4>
                      <el-divider class="mb-0 mt-0"></el-divider>
                      <div
                        class="each-document"
                        v-for="(doc, index) of seletedStepData.company_documents
                          .requested"
                        :key="index"
                      >
                        <el-button
                          circle
                          class="float-right"
                          size="mini"
                          type="danger"
                          icon="el-icon-close"
                          @click="removeComapnyDocument('requested', index)"
                        ></el-button>
                        <h4>{{ doc.title }}</h4>
                      </div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="scrollable-min"
                      v-if="
                        seletedStepData &&
                        seletedStepData.company_documents.custom &&
                        seletedStepData.company_documents.custom.length
                      "
                    >
                      <h4 class="blue-2 card-title">
                        <i class="el-icon-document"></i> Custom Documents
                      </h4>
                      <el-divider class="mb-0 mt-0"></el-divider>
                      <div
                        class="each-document"
                        v-for="(doc, index) of seletedStepData.company_documents
                          .custom"
                        :key="index"
                      >
                        <el-button
                          circle
                          class="float-right"
                          size="mini"
                          type="danger"
                          icon="el-icon-close"
                          @click="removeComapnyDocument('custom', index)"
                        ></el-button>
                        <h4>{{ doc.title }}</h4>
                      </div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="scrollable-min"
                      v-if="
                        seletedStepData &&
                        seletedStepData.company_documents.standard &&
                        seletedStepData.company_documents.standard.length
                      "
                    >
                      <h4 class="blue-2 card-title">
                        <i class="el-icon-document"></i> Standard Documents
                      </h4>
                      <el-divider class="mb-0 mt-0"></el-divider>
                      <div
                        class="each-document"
                        v-for="(doc, index) of seletedStepData.company_documents
                          .standard"
                        :key="index"
                      >
                        <el-button
                          circle
                          class="float-right"
                          size="mini"
                          type="danger"
                          icon="el-icon-close"
                          @click="removeComapnyDocument('standard', index)"
                        ></el-button>
                        <h4>{{ doc.title }}</h4>
                      </div>
                    </el-card>
                  </div>
                  <div v-else>
                    <h3 class="text-center mt-2 font-weight-normal">
                      No Documents
                    </h3>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-dialog>
      <dialog-component
        :containerMaxHeight="getIsMobile ? '100%' : '60%'"
        :containerMinWidth="getIsMobile ? '100%' : '30%'"
        title="Update Logo"
        :visible="logoUploadModal"
        @before-close="closeLogoModal"
      >
        <div class="uploader">
          <vue-anka-cropper
            class="upload-file"
            :options="{
              aspectRatio: false,
              closeOnSave: true,
              cropArea: 'box',
              croppedHeight: 1000,
              croppedWidth: 1000,
              cropperHeight: false,
              dropareaMessage:
                ' Drag & Drop  file here or use the button below.',
              frameLineDash: [1, 1000],
              frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
              handleFillColor: 'rgba(255, 255, 255, 0.2)',
              handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
              handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
              handleSize: 5,
              handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
              layoutBreakpoint: 40,
              maxCropperHeight: 1000,
              maxFileSize: 8000000,
              overlayFill: 'rgba(0, 0, 0, 0.5)',
              previewOnDrag: true,
              previewQuality: 0.65,
              resultQuality: 0.8,
              resultMimeType: [
                'image/jpeg',
                'image/png',
                'image/jpg',
                'image/gif',
              ],
              selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',
              showPreview: true,
              skin: 'light',
              uploadData: {},
              uploadTo: false,
            }"
            name="logo"
            @cropper-error="fileError"
            @cropper-preview="uploadFile"
            @cropper-file-selected="uploadFile"
            @cropper-saved="uploadLogo"
            v-loading="upLoadEffect"
            accept="image/jpeg, image/png, image/jpg, image/gif"
          ></vue-anka-cropper>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              outerVisible = false;
              files = null;
              closeLogoModal();
            "
            >Cancel</el-button
          >
          <el-button type="primary" @click="uploadLogo">Save</el-button>
        </span>
      </dialog-component>
      <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="showAddgroupDialog = false"
    >
      <el-form class="m-tb-2">
        <div v-loading="loadingSaveGroup">
          <p>Group Title</p>
          <el-input
            type="text"
            v-model="groupName"
            placeholder="Enter Title"
            :style="{ height: '50px' }"
          ></el-input>
        </div>
      </el-form>
      <span slot="footer" class="text-center">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="showAddGroupDialog = false"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="saveGroup"
          :disabled="!groupName || !groupName.trim()"
        >
          <i class="fa fa-check-square-o"></i> Save Group
        </button>
      </span>
    </dialog-component>
      <dialog-component
        :title="'Parent Templates(Header Footer)'"
        :visible="emailTemplatesParentListDialog"
        @before-close="emailTemplatesParentListDialog = false"
        :containerWidth="'100%'"
      >
        <singleEntityTemplatesList
          :content="formBuilder.approval_mail_template"
          style="padding: 10px"
          entity_id
          type="PARENT"
          v-on:closeDialog="closeEmailFooter"
        />
      </dialog-component>
      <dialog-component
        :title="'Parent Templates(Header Footer)'"
        :visible="receiverEmailTemplatesParentListDialog"
        @before-close="receiverEmailTemplatesParentListDialog = false"
        :containerWidth="'100%'"
      >
        <singleEntityTemplatesList
          :content="formBuilder.receiver_mail_template"
          style="padding: 10px"
          entity_id
          type="PARENT"
          v-on:closeDialog="closeEmailFooterReceiver"
        />
      </dialog-component>
      <dialog-component
        :title="this.previewTemplateName"
        :visible="showDetailedView"
        :containerWidth="getIsMobile ? '100%' : '70%'"
        :containerMaxHeight="'90%'"
        v-loading="modalLoading"
        element-loading-text="Please wait, we are getting things ready for you."
        element-loading-spinner="pencil"
        element-loading-background="rgba(202, 197, 197, 0.173)"
        class="detailedTemplatesView"
        @before-close="resetTemplateData"
        :isShowFooter="false"
      >
        <el-collapse v-model="activeTempIds">
          <el-collapse-item
            v-for="(temp, index) in detailedTempIds"
            :key="index"
            :name="temp.id"
            :title="temp.name"
          >
            <viewTemplate :templateIdViewId="temp.id"></viewTemplate>
          </el-collapse-item>
        </el-collapse>
      </dialog-component>
      <dialog-component
        title="Create Template with AI"
        :visible.sync="visible"
        @before-close="visible = false"
        width="400px"
        @close="resetForm"
      >
        <el-form>
          <el-form-item label="Form Template Name">
            <el-input
              type="text"
              placeholder="Enter the form template name"
              @keydown.enter.prevent.native="handleSubmit"
              v-model="templatename"
              @blur="checkDuplicateTemplateName"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="handleSubmit"
            :disabled="!templatename || isDuplicate"
          >
            Create
          </el-button>
        </span>
      </dialog-component>
      <!-- <el-drawer
        :visible.sync="showChatbot"
        title="ChatGPT"
        size="35%"
        :direction="direction"
        v-model="showChatbot"
        :before-close="handleClose"
      >
        <chatgpt
          @close-chatbot="CloseChatBot"
          @copy-content="updateEditorContent"
          :entity_fields="allMentionableFields"
          :entity="true"
          :isFromBuilder="true"
          type="form_builder"
        />
      </el-drawer> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { getCodeByName } from "@/utils/appHelper";
var iconsData = require("@/assets/data/icons.json");
import UserHelper from "@/mixins/UserHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import viewTemplate from "@/components/templates/ViewTemplate";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import companyDocuments from "@/constants/lg-en/companyDocuments";
import TextEditor from "../contentBlocks/TextEditor";
import draggable from "vuedraggable";
import { bus } from "../../main";
import * as moment from "moment";
import { makeSlug } from "@/helpers/appHelper";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
// import chatgpt from "../ChatGpt-EmailTemplates.vue";
import { fetchGroups, addSingleGroupToLocal } from "@/repo/groupsRepo";
import {
  addSingleFormbuilderToLocal,
  updateFormbuilderData,
} from "@/repo/formbuilderDetails";

const weeksArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const timezonesArray = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis",
  "UTC",
];

let minutesArray = [];
let hoursArray = [];
let daysArray = [];
let yearsArray = [];
export default {
  data() {
    return {
      // drawer: false,
      showDetailedView: false,

      visible: false,
      templatename: "",
      isDuplicate: false,
      // direction: "rtl",
      previewTemplateName: "",
      templateData: [],
      value2: "",
      templateId: null,
      showChatbot: false,
      isminutesIndeterminate: true,
      ishoursIndeterminate: true,
      isdaysIndeterminate: true,
      isweeksIndeterminate: true,
      ismonthsIndeterminate: true,
      isyearsIndeterminate: true,
      minutes: minutesArray,
      hours: hoursArray,
      days: daysArray,
      weeks: weeksArray,
      months: monthsArray,
      years: yearsArray,
      time_zone: timezonesArray,
      modalLoading: false,

      action: "Add",
      userTypeValue: "",
      company_users: [],
      form_builder: {},
      formBuilder: {
        name: "",
        steps: [],
        description: "",
        code: "",
        user_type: "",
        icon_type: "",
        icon: "",
        form_builders_owner: "",
        form_builder_filter: "",
        logo: "",
        entity_id: "",
        form_builder_owners: [],
        approval_users: [],
        approve_all_user: false,
        is_approval_form: false,
        priority_based: false,
        approval_receiver_permissions: {
          view: true,
          edit: false,
          delete: false,
        },
        settings: {
          is_time_bond: false,
          disableDate: new Date(),
          formbuilder_start_time: "",
          formbuilder_date_range: [new Date(), new Date()],
          formbuilder_end_time: "",

          frequency: false,
          limit_responses: false,
          is_allowed_to_all_users: false,
          no_responses_limited: 1,
          secure_access: false,
          access_to_all: true,
          edit_responses: false,
          view_responses: false,
          success_and_redirect_page: {
            thankyou_page: "",
            redirect_to: false,
            redirect_to_url: "",
            notify_sender: true,
            notify_reciever: true,
            mail_recievers: [],
          },
          allowed_entity_users: [],
          schedules: {
            entity_id: null,
            filters: [],
            entity_data: [],
            limit: 0,
            start_time: "",
            end_time: "",
            interval: "",
            expired_time_interval_type: "",
            expired_time_interval_value: 0,
            interval_value: 0,
            daily_start_time: "",
            minutes: [],
            hours: [],
            days: [],
            months: [],
            days_of_week: [],
            years: [],
            checkAllminutes: false,
            checkAllhours: false,
            checkAlldays: false,
            checkAllweeks: false,
            checkAllmonths: false,
            checkAllyears: false,
            time_zone: "",
            status: false,
            multiple: false,
          },
          interval_settings: {
            status: false,
            start_time: "",
            end_time: "",
            type: "",
            expired_time_interval_type: "",
            expired_time_interval_value: 0,
          },
          reminder_settings: {
            type: "FORMBUILDER",
            formBuilder_details_id: "",
            reminder_start_time: "",
            reminder_end_time: "",
            reminder_interval_type: "",
            reminder_interval_value: 0,
            status: true,
            first_reminder_interval_type: "",
            first_reminder_interval_value: 0,
          },
        },
        customization: {
          add_button_label: "Add",
          finish_button_label: "Finish",
          show_link_to_be_copy: true,
          show_detailed_link: true,
          show_initial_confirmation: true,
          show_try_esigns_and_content: true,
          show_title_bar: true,
        },
        expiration_settings: {
          expiry_type: "",
          expiry_duration: 0,
        },
        groups: this.$route.query.group ? [this.$route.query.group] : [],
        approval_mail_template: {
          subject: "Approval request",
          body: "<p>Hello,</p>\n\n<p>Please click on the below link to approve it.</p>\n\n<p>Thank you.</p>\n",
          cc_user: [],
          header_body: "",
          footer_body: "",
          name: "",
        },
        receiver_mail_template: {
          subject: "Form filled",
          body: "<p>Hello,</p>\n\n<p>We would like to confirm that we have received your response to the form. Thank you for taking the time to complete it.</p>\n\n<p>Thank you.</p>\n",
          cc_user: [],
          header_body: "",
          footer_body: "",
          name: "",
        },
        primary_fields: [],
      },
      default_settings: {
        frequency: false,
        limit_responses: false,
        no_responses_limited: 1,
        is_allowed_to_all_users: false,
        success_and_redirect_page: {
          thankyou_page: "",
          redirect_to: false,
          redirect_to_url: "",
          notify_sender: true,
          notify_reciever: true,
        },
        allowed_entity_users: [],

        // schedules: {
        //   entity_id: null,
        //   filters: [],
        //   limit: 0,
        //   start_time: null,
        //   end_time: null,
        //   interval: "",
        //   expired_time_interval_type: "",
        //   expired_time_interval_value: 0,
        //   daily_start_time: "",
        //   entity_data: [],
        //   status: false,
        // },
        // interval_settings: {
        //   status: false,
        //   start_time: "",
        //   end_time: "",
        //   type: "",
        //   expired_time_interval_type: "",
        //   expired_time_interval_value: 0,
        // },
        // reminder_settings: {
        //   type: "WORKFLOW",
        //   formBuilder_details_id: "",
        //   reminder_start_time: "",
        //   reminder_end_time: "",
        //   reminder_interval_type: "",
        //   reminder_interval_value: 0,
        //   status: false,
        // },
      },

      employeeTypes: [
        {
          name: "W2 Salaried Employee",
          value: "W2_SALARIED_EMPLOYEE",
        },
        {
          name: "1099 Employee",
          value: "1099_EMPLOYEE",
        },
      ],

      allOwnerTypes: [
        {
          label: "Company User",
          value: "USER",
        },
        {
          label: "Application User",
          value: "ENTITY",
        },
        {
          label: "Anyone",
          value: "COMPANY",
        },
        {
          label: "Anonymous",
          value: "ANONYMOUS",
        },
      ],

      assets: [
        {
          name: "Offer Letter",
          value: "OFFER_LETTER",
          slug: "offer-letter",
        },
        {
          name: "Company Documents",
          value: "COMPANY_DOCUMENTS",
          slug: "company-documents",
          company_documents: {
            static: [],
            custom: [],
            standard: [],
            requested: [],
          },
        },
        // {
        //   name: "Relational Data",
        //   value: "RELATIONAL_DATA",
        //   slug: "relational-data",
        //   relational_data: {
        //     template: "",
        //     fields: [],
        //     name: ""
        //   }
        // }
      ],

      standardDocumentsList: companyDocuments.STANDARD_DOCUMENTS_LIST,
      templateName: "",
      benefitNames: {
        OFFER_LETTER: "Offer Letter",
        COMPANY_DOCUMENTS: "Company Documents",
        RELATIONAL_DATA: "Relational Data",
      },
      benefitIcons: {
        OFFER_LETTER: "employee-CV.svg",
        COMPANY_DOCUMENTS: "document-folder.svg",
        RELATIONAL_DATA: "document-folder.svg",
        TEMPLATE: "document-folder.svg",
        ENTITY: "document-folder.svg",
      },
      loading: true,
      currentTemplate: null,
      currentAsset: null,
      formBuilderId: null,
      seletedStepData: {
        company_documents: {
          static: [],
          standard: [],
          custom: [],
          requested: [],
        },
        relational_data: {
          name: "",
          template: "",
          fields: [],
        },
      },
      selectedStepIndex: -1,
      selectedRelationalDataFields: [],
      configureTitles: {
        sender_title: "",
        receiver_title: "",
        isLoginEntity: false,
        applyRelationship: false,
        linkedToUserTemplate: false,
        selectedTemplates: [],
        is_repeatable: false,
        max_records: 3,
        min_records: 1,
        linked_field: "",
        linked_min_field: "",
        contact_entity: "",
        contact_entity_name: "",
        contact_entity_type: "",
        entity_table_configuration: [],
      },
      configureSettings: false,
      configureDocumentSettings: false,
      configureCompanyDocumentsVisible: false,
      configureStepTitlesVisible: false,
      configureRelationalDataVisible: false,
      searchDocumentFormData: {
        type: "",
        group_id: "",
        category_id: "",
        searchedDocuments: [],
      },
      allUserTypes: [],
      iconPickerdialogVisible: false,
      iconSearch: "",
      formbuilderIconPath: "block-portfolio.svg",
      iconType: "DEFAULT",
      templateTitle: {
        sender_title: "",
        reciever_title: "",
      },
      customTemplateComponent: {
        title: "",
        description: "",
        templateName: "",
        primaryFieldsOption: [
          {
            value: "HTML",
            label: "HTML",
          },
          {
            value: "CSS",
            label: "CSS",
          },
          {
            value: "JavaScript",
            label: "JavaScript",
          },
        ],
        primaryFields: [],
      },
      activeStep: 0,
      templateSearchString: "",
      formBuilderEditStep: "Form Builder Details",
      entitySearchString: "",
      currentEntity: null,
      entitiesData: [],
      templatesData: [],
      allEntitiesData: [],
      allTemplatesData: [],
      logoUploadModal: false,
      relationshipsData: [],
      refresh: true,
      pickerOptions: {
        disabledDate: this.disabledDatesUptoYesterday,
        shortcuts: [
          // {
          //   text: 'Today',
          //   onClick() {
          //     bus.$emit("date_range",'TODAY')
          //   }
          // },
          {
            text: "24 Hours",
            onClick() {
              bus.$emit("date_range", "TOMARROW");
            },
          },
          {
            text: "Weekly",
            onClick() {
              bus.$emit("date_range", "WEEK");
            },
          },
          {
            text: "Bi-weekly",
            onClick() {
              bus.$emit("date_range", "BIWEEKLY");
            },
          },
          {
            text: "Monthly",
            onClick() {
              bus.$emit("date_range", "MONTHLY");
            },
          },
          {
            text: "Quarterly",
            onClick() {
              bus.$emit("date_range", "QUARTERLY");
            },
          },
          {
            text: "Half-yearly",
            onClick() {
              bus.$emit("date_range", "HALFYEARLY");
            },
          },
          {
            text: "Yearly",
            onClick() {
              bus.$emit("date_range", "YEARLY");
            },
          },
        ],
      },
      entityTemplates: [],
      entityUsers: [],
      showApprovers: false,
      userTypes: [
        { value: "APPLICATION", label: "Application Users" },
        { value: "COMPANY", label: "Company Users" },
        { value: "LINKEDFIELD", label: "Based on filled data" },
      ],
      selectedUserType: "",
      selectedCompanyUserType: "",
      selectedEntityUserType: "",
      selectedApplicationUserEmails: [],
      selectedCompanyUserEmails: [],
      allContacts: [],
      activeApprovalTab: "users",
      activeMainTab: "primary_settings",
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorConfig1: {},
      allMentionableFields: [],
      formbuilderGroupsData: [],
      loadingSaveGroup: false,
      showAddGroupDialog: false,
      groupName: "",
      emailTemplatesParentListDialog: false,
      receiverEmailTemplatesParentListDialog: false,
      entityTables: [],
      entityTablesRefresh: false,
      entity_table_configuration: [],
      entityFilters: [],
      files: null,
      upLoadEffect: false,
      getAllEntityGroupsData: [],
      allFormbuilderFields: [],
      detailedTempIds: [],
      activeTempIds: [],
    };
  },
  components: {
    TextEditor,
    draggable,
    viewTemplate,
    // chatgpt,
    singleEntityTemplatesList: () =>
      import("@/components/emailTemplates/singleEntityTemplatesList.vue"),
    vueAnkaCropper,
    // EntitiesEmailsSelection,
    // WorkflowSettings,
  },
  mounted() {
    // this.loading = true;
    this.checkMonths();
    this.checkHours();
    this.checkDays();
    this.checkYears();
    this.getTimeZone();
    this.getNecessaryInfo();
    this.$store.dispatch("formBuilders/clearErrors");
    this.configCKeditor();
    this.fetchCompanyGroups();
    // this.loading = false;
  },
  mixins: [UserHelper, DocumentsHelper, TemplateBuilderHelper],
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
      "getEntityDataById",
    ]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilderAddData",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderUpdateData",
      "getFormBuilderErrors",
      "getformbuildertemplateDatawithAi",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getCompanyUsers",
      "getUserTypeList",
      "getActiveWorkspace",
    ]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),

    ...mapGetters("entityGroups", [
      // "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    errors() {
      return {};
    },
    iconList() {
      return iconsData.filter((e) => {
        if (this.iconSearch) {
          if (
            e.name.toString().includes(this.iconSearch.toString().toLowerCase())
          ) {
            return e;
          }
        } else {
          return e;
        }
      });
    },
    getRelationshipText() {
      if (this.seletedStepData.entity && this.formBuilder?.entity_id) {
        let selectedEntity1 = (this.allEntitiesData || []).find(
          (e) => e._id == this.formBuilder.entity_id
        );
        let selectedEntity2 = (this.allEntitiesData || []).find(
          (e) => e._id == this.seletedStepData.entity
        );
        if (selectedEntity1?.name && selectedEntity2?.name) {
          return `Link ${selectedEntity2?.name} to ${selectedEntity1?.name}`;
        }
        return "Use relational data?";
      }
      return "Use relational data?";
    },
    templates() {
      return this.allTemplatesData ? this.allTemplatesData : [];
    },
    templateNames() {
      if (!this.templates) {
        return;
      }
      const data = {};
      this.templates.forEach(
        (template) => (data[template._id] = template.name)
      );
      return data;
    },
    entityIcons() {
      if (!this.entities) {
        return;
      }
      const data = {};
      this.entities.forEach((entity) => (data[entity._id] = entity.icon));
      return data;
    },
    entities() {
      return this.allEntitiesData ? this.allEntitiesData : [];
    },
    entityNames() {
      if (!this.entities) {
        return;
      }
      const data = {};
      this.entities.forEach((entity) => (data[entity._id] = entity.name));
      return data;
    },
    checkSubmitDisabled() {
      if (
        this.seletedStepData.type == "ENTITY" &&
        (!this.configureTitles.selectedTemplates ||
          !this.configureTitles.selectedTemplates.length)
      ) {
        return true;
      } else if (
        this.seletedStepData?.standard_template &&
        !this.configureTitles.contact_entity
      ) {
        return true;
      }
      return false;
    },
    getPrevStepFields() {
      let prevTemplates = [];
      let result = [];
      this.formBuilder.steps.forEach((step, index) => {
        if (index < this.selectedStepIndex) {
          if (step.type == "TEMPLATE" && !step?.settings?.is_repeatable) {
            prevTemplates.push({
              template_id: step.template,
              type: "TEMPLATE",
              stepIndex: index,
            });
          } else if (step.type == "ENTITY") {
            let currentEntity = (this.allEntitiesData || []).find(
              (e) => e._id == step.entity
            );
            if (currentEntity?.templates) {
              prevTemplates = [
                ...prevTemplates,
                ...currentEntity?.templates.map((e) => ({
                  template_id: e.template_id,
                  type: "ENTITY",
                  name: currentEntity.name,
                  stepIndex: index,
                })),
              ];
            }
          }
        }
      });
      if (prevTemplates && prevTemplates.length) {
        prevTemplates.forEach((temp) => {
          result = [
            ...result,
            ...this.getTemplateFields(
              temp.template_id,
              temp.name,
              temp.stepIndex,
              temp.type
            ),
          ];
        });
      }
      return result;
    },
    checkIsInvolved() {
      let isInvolved = this.formBuilder.steps.find(
        (e) =>
          e?.settings?.is_repeatable &&
          ((e?.settings?.linked_field &&
            e.settings.linked_field.includes("#") &&
            e.settings.linked_field.split("#")[1] ==
              this.seletedStepData.template) ||
            (e?.settings?.linked_min_field &&
              e.settings.linked_min_field.includes("#") &&
              e.settings.linked_min_field.split("#")[1] ==
                this.seletedStepData.template))
      );
      if (isInvolved) {
        return false;
      }
      return true;
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
    selectedUserEntity() {
      if (
        this.formBuilder?.entity_id &&
        this.formBuilder?.form_builders_owner == "ENTITY"
      ) {
        let selectedEntity = (this.allEntitiesData || []).find(
          (e) => e._id == this.formBuilder.entity_id
        );
        if (selectedEntity?.name && selectedEntity?.entity_type) {
          return selectedEntity;
        }
        return null;
      }
      return null;
    },
    getAllFields() {
      let allFields = [];
      if (this.formBuilder && this.formBuilder.steps) {
        let entitySteps = this.formBuilder.steps.filter(
          (step) => step.type == "ENTITY" && step.entity
        );
        if (entitySteps && entitySteps.length) {
          entitySteps.forEach((entity) => {
            let matchedEntity = (this.allEntitiesData || []).find(
              (e) => e._id == entity.entity
            );
            if (matchedEntity?.templates) {
              const currentTemplates = (this.allTemplatesData || []).filter(
                (e) =>
                  matchedEntity?.templates
                    .flatMap((e) => e.template_id)
                    .includes(e._id)
              );
              if (currentTemplates && currentTemplates.length) {
                currentTemplates.forEach((temp) => {
                  if (temp?.sections && temp?.sections?.[0]) {
                    temp.sections[0].fields.forEach((fl) => {
                      if (fl.inputType == "ENTITY") {
                        let selectedEntity = (this.allEntitiesData || []).find(
                          (e) => e._id == fl.entity_id
                        );
                        if (
                          selectedEntity?.entity_type == "INDIVIDUAL" ||
                          selectedEntity?.entity_type == "BUSINESS"
                        ) {
                          allFields.push({
                            label:
                              selectedEntity.name +
                              "-" +
                              temp.name +
                              "-" +
                              fl.label,
                            field: fl.key,
                            key:
                              entity.entity +
                              "#" +
                              selectedEntity._id +
                              "#" +
                              temp._id +
                              "#" +
                              fl.key,
                          });
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        }
      }
      return allFields;
    },
  },
  methods: {
    openDialog() {
      this.visible = true;
    },

    // handleClose() {
    //   this.showChatbot = false;
    // },
    checkDuplicateTemplateName() {
      this.isDuplicate = this.templates.some(
        (template) => template.name === this.templatename
      );

      if (this.isDuplicate) {
        return this.$message.error(
          "Template Already Exists with the same name"
        );
      }
    },
    resetForm() {
      this.templatename = "";
    },
    getAllFormbuilderFields(types) {
      let allFields = [];
      if (this.formBuilder && this.formBuilder.steps) {
        this.formBuilder.steps.forEach((step, index) => {
          if (step.type == "ENTITY" && step.entity) {
            let stepName =
              step.settings && step.settings.sender_title
                ? step.settings.sender_title
                : this.entityNames[step.entity];
            let matchedEntity = (this.allEntitiesData || []).find(
              (e) => e._id == step.entity
            );
            if (matchedEntity?.templates) {
              const currentTemplates = (this.allTemplatesData || []).filter(
                (e) =>
                  matchedEntity?.templates
                    .flatMap((e) => e.template_id)
                    .includes(e._id)
              );
              if (currentTemplates && currentTemplates.length) {
                currentTemplates.forEach((temp) => {
                  if (temp?.sections && temp?.sections?.[0]) {
                    temp.sections[0].fields.forEach((fl) => {
                      if (types.includes(fl.inputType)) {
                        allFields.push({
                          label:
                            stepName + " - " + temp.name + " - " + fl.label,
                          field: fl.key,
                          key: index + "#" + temp._id + "#" + fl.key,
                        });
                      }
                    });
                  }
                });
              }
            }
          } else if (step.type == "TEMPLATE" && step.template) {
            let stepName =
              step.settings && step.settings.sender_title
                ? step.settings.sender_title
                : this.templateNames[step.entity];
            const currentTemplate = (this.allTemplatesData || []).find(
              (e) => e._id == step.template
            );
            if (currentTemplate?.sections) {
              currentTemplate?.sections[0]?.fields.forEach((fl) => {
                if (types.includes(fl.inputType)) {
                  allFields.push({
                    label:
                      stepName +
                      " - " +
                      currentTemplate.name +
                      " - " +
                      fl.label,
                    field: fl.key,
                    key: index + "#" + currentTemplate._id + "#" + fl.key,
                  });
                }
              });
            }
          }
        });
      }
      return allFields;
    },
    createTemplate() {
      this.TemplatewithAi();
    },
    uploadFile(file) {
      if (file && typeof file === "string") {
        // Convert data URL to a Blob
        this.files = this.dataURLtoFile(file, "image1");
      } else {
        this.files = file;
      }
    },

    fileError() {
      this.$alert("File Supported: JPG, PNG, JPEG, GIF");
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    disabledDatesUptoYesterday(time) {
      const currentDateTime = new Date();
      const yesterdayDate = new Date(currentDateTime);
      yesterdayDate.setDate(currentDateTime.getDate() - 1);
      return time.getTime() < yesterdayDate.getTime();
    },
    ReseticonPickerdialogVisible() {
      this.iconPickerdialogVisible = false;
    },
    ResetlogoUploadModal() {
      this.logoUploadModal = false;
    },
    checkDuplicateField(index, value, type) {
      if (this.entity_table_configuration) {
        let otherField = this.entity_table_configuration.find(
          (f, i) => i != index && f[type] == value
        );
        return otherField ? true : false;
      }
      return false;
    },
    removeNewField(index) {
      this.entity_table_configuration.splice(index, 1);
    },
    addNewField() {
      this.entityTablesRefresh = true;
      this.entity_table_configuration.push({
        applyRelationship: false,
        min_records: 1,
        max_records: 3,
        linked_field: "",
        linked_min_field: "",
        selectField: "",
      });
      setTimeout(() => {
        this.entityTablesRefresh = false;
      }, 100);
    },
    checkDuplicate(index, value, type) {
      if (this.formBuilder.approval_users) {
        let otherUsers = this.formBuilder.approval_users.find(
          (user, i) => i != index && user[type] == value
        );
        return otherUsers ? true : false;
      }
      return false;
    },
    CloseChatBot() {
      this.showChatbot = false;
    },

    updateApplicationUserType(user) {
      this.$set(user, "preferred_application_users", []);
    },
    updateCompanyUsers(value, user) {
      this.$set(user, "preferred_company_users", []);
      if (value) {
        let users = this.getCompanyUsersByType(value);
        if (users && users.length == 1) {
          user.preferred_company_users = [...users.map((e) => e.email)];
        }
      }
    },
    chooseFromParentEmailTemplatesReceiver() {
      this.receiverEmailTemplatesParentListDialog = true;
    },
    closeEmailFooterReceiver() {
      this.receiverEmailTemplatesParentListDialog = false;
    },
    resetParentReceiver() {
      if (this.formBuilder?.receiver_mail_template) {
        this.formBuilder.receiver_mail_template.footer_body = "";
        this.formBuilder.receiver_mail_template.header_body = "";
        this.formBuilder.receiver_mail_template.name = "";
      }
    },
    chooseFromParentEmailTemplates() {
      this.emailTemplatesParentListDialog = true;
    },
    closeEmailFooter() {
      this.emailTemplatesParentListDialog = false;
    },
    resetParent() {
      if (this.formBuilder?.approval_mail_template) {
        this.formBuilder.approval_mail_template.footer_body = "";
        this.formBuilder.approval_mail_template.header_body = "";
        this.formBuilder.approval_mail_template.name = "";
      }
    },
    handleSubmit() {
      this.checkDuplicateTemplateName();
      if (this.isDuplicate) {
        return;
      }
      let isFromAdd = this.$route.path.includes("/add");
      if (this.templatename) {
        this.$store.commit(
          "formBuilders/setformbuildertemplateDatawithAi",
          this.formBuilder,
          { root: true }
        );
        if (isFromAdd) {
          this.$router.push({
            path: "/template/create-with-ai",
            query: {
              isFromFormBuilder: true,
              template_name: this.templatename,
              isFromAdd: isFromAdd,
            },
          });
        } else {
          this.$router.push({
            path: "/template/create-with-ai",
            query: {
              isFromFormBuilder: true,
              template_name: this.templatename,
              isFromAdd: isFromAdd,
              formId: this.$route?.params?.form_builder_id,
            },
          });
        }
        this.visible = false;
      }
    },

    OpenwithAi() {
      this.showChatbot = !this.showChatbot;
    },
    configCKeditor() {
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><span>[[{label}]]</span><span>&nbsp;</span>",
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
        {
          feed: "/tags?name={encodedQuery}",
          marker: "#",
        },
      ];
      this.editorConfig1 = {
        ...this.editorConfig,
        id: "approval_mail_template",
      };
    },
    getFormBuilderAllFields() {
      let result = [];
      //Add fields from steps
      this.formBuilder.steps.forEach((step) => {
        if (step?.type == "ENTITY") {
          let currentEntityTemplates = this.getEntityTemplates(step.entity);
          let selectedEntity = (this.allEntitiesData || []).find(
            (e) => e._id == step.entity
          );
          result = [
            ...result,
            ...this.getFieldsFromTemplate(
              currentEntityTemplates,
              selectedEntity
            ),
          ];
        } else if (!step?.settings?.is_repeatable && step?.type == "TEMPLATE") {
          let templateData = (this.allTemplatesData || []).filter(
            (te) => te._id === step.template
          );
          result = [...result, ...this.getFieldsFromTemplate(templateData)];
        }
      });
      //adding fields from user
      if (
        this.formBuilder?.form_builders_owner == "ENTITY" &&
        this.formBuilder?.entity_id
      ) {
        //adding all templates user entity fields
        let currentEntityTemplates = this.getEntityTemplates(
          this.formBuilder.entity_id
        );
        let selectedEntity = (this.allEntitiesData || []).find(
          (e) => e._id == this.formBuilder.entity_id
        );
        let fields = this.getFieldsFromTemplate(
          currentEntityTemplates,
          selectedEntity
        );
        fields = fields.map((e) => {
          e.mention_key = "user/" + e.mention_key;
          e.template_key = "user/" + e.template_key;
          e.label = "User-" + e.label;
          return e;
        });
        result = [...result, ...fields];
      } else {
        //adding only user fields
        [
          "first_name",
          "last_name",
          "email",
          "phone",
          "title",
          "address",
          "name",
        ].forEach((f) => {
          result.push({
            mention_key: "user/" + f,
            label:
              "User - " + this.capitalizeFirstLetter(f.replaceAll("_", " ")),
            template_key: "user/" + f,
            key: f,
            input_type: "SINGLE_LINE_TEXT",
          });
        });
      }
      //adding approval user details
      if (this.activeMainTab == "approval_settings") {
        [
          "first_name",
          "last_name",
          "email",
          "phone",
          "title",
          "address",
          "name",
        ].forEach((f) => {
          result.push({
            mention_key: "approver/" + f,
            label:
              "Approver - " +
              this.capitalizeFirstLetter(f.replaceAll("_", " ")),
            template_key: "approver/" + f,
            key: f,
            input_type: "SINGLE_LINE_TEXT",
          });
        });
      }
      return result;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFieldsFromTemplate(templateDataList, entity = null) {
      let result = [];
      if (templateDataList && templateDataList.length) {
        templateDataList.forEach((t) => {
          let temp = JSON.parse(JSON.stringify(t));
          if (temp?.sections?.[0]?.fields) {
            result = [
              ...result,
              ...temp.sections[0]?.fields.map((e) => {
                e.template_id = temp._id;
                if (entity?._id) {
                  e.entity_key = entity.name;
                  e.entity_id = entity._id;
                  e.mention_key = entity._id + "#" + temp._id + "#" + e.key;
                  e.label = entity.name + "-" + temp.name + "-" + e.label;
                  e.template_key = entity._id + "#" + temp._id + "#" + e.key;
                } else {
                  e.mention_key = temp._id + "#" + e.key;
                  e.label = temp.name + "-" + e.label;
                  e.template_key = temp._id + "#" + e.key;
                }
                return e;
              }),
            ];
          }
        });
      }
      return result;
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    onApprovalTabChange() {
      // getting all entity and template field that are present in form
      this.allMentionableFields = this.getFormBuilderAllFields();
      this.allMentionableFields = this.allMentionableFields.map((item) => {
        return {
          label: item.label,
          template_key: item.template_key,
          id: item.mention_key,
          input_type: item.inputType,
        };
      });
      if (
        this.formBuilder?.is_approval_form &&
        this.formBuilder?.approval_mail_template?.body
      ) {
        this.formBuilder.approval_mail_template.body =
          this.mapMailTemplatevariables(
            this.formBuilder.approval_mail_template.body,
            true
          );
      }
      if (this.formBuilder?.receiver_mail_template?.body) {
        this.formBuilder.receiver_mail_template.body =
          this.mapMailTemplatevariables(
            this.formBuilder.receiver_mail_template.body,
            true
          );
      }
    },
    removeUser(index) {
      this.formBuilder.approval_users.splice(index, 1);
    },
    checkIsRelational(type) {
      if (
        this.formBuilder.form_builders_owner == "ENTITY" &&
        this.formBuilder.entity_id
      ) {
        return this.relationshipsData.find(
          (e) =>
            (e.child_entity_id == this.formBuilder.entity_id &&
              e.parent_entity_id == type) ||
            (e.child_entity_id == type &&
              e.parent_entity_id == this.formBuilder.entity_id)
        )
          ? true
          : false;
      }
      return false;
    },
    getUserTypeContacts(type) {
      return this.allContacts.filter((e) => {
        if (e?.contact_types) {
          return e.contact_types.find(
            (el) => el?.contact_type?._id == type || el?.contact_type == type
          )
            ? true
            : false;
        }
      });
    },
    addInitialUsers() {
      if (
        !this.formBuilder.approval_users ||
        !this.formBuilder.approval_users.length
      ) {
        this.addApprovers();
      }
    },
    getCompanyUsersByType(type) {
      return this.company_users.filter((e) => {
        if (e?.rolesList) {
          return e.rolesList.find((el) => el?.userType?._id == type)
            ? true
            : false;
        } else {
          return e.roles.find((el) => el?.userType_id == type) ? true : false;
        }
      });
    },
    async fetchContacts() {
      try {
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        });
        this.allContacts = this.getAllContacts.data;
      } catch (err) {
        this.loading = false;
      }
    },
    getContactTypeIdFromValue(value) {
      let Id = "";
      try {
        if (this.getAllContactTypes && this.getAllContactTypes.data) {
          this.getAllContactTypes.data.forEach((type) => {
            if (type.name == value) {
              Id = type._id;
            }
          });
        }
      } catch (err) {
        console.log("fetchContacts", err);
      }
      if (Id == "") {
        try {
          if (this.getAllContactTypes) {
            this.getAllContactTypes.forEach((type) => {
              if (type.name == value) {
                Id = type._id;
              }
            });
          }
        } catch (err) {
          console.log("getContactTypeIdFromValue", err);
        }
      }
      return Id;
    },
    addApprovers() {
      this.formBuilder.approval_users.push({});
    },
    //checking is standard template removed for individual entity
    checkForIndividual(temp) {
      if (
        this.formBuilder.form_builders_owner === "ENTITY" &&
        this.configureTitles.isLoginEntity
      ) {
        return;
      }
      let selectedEntity = (this.allEntitiesData || []).find(
        (e) => e._id == this.seletedStepData.entity
      );
      if (selectedEntity?.entity_type == "INDIVIDUAL") {
        let isStandardTemplate = (this.allTemplatesData || []).find(
          (el) => temp.indexOf(el._id) !== -1 && el.type == "STANDARD"
        );
        let standardTemplate = (this.allTemplatesData || []).find(
          (el) => el.type == "STANDARD"
        );
        if (!isStandardTemplate && standardTemplate?._id) {
          this.$message.error(
            `Individual entity must have 'contact details' template`
          );
          this.configureTitles.selectedTemplates.push(standardTemplate._id);
        }
      }
    },
    updateEntityName(data) {
      let selectedEntity = (this.allEntitiesData || []).find(
        (e) => e._id == data
      );
      if (selectedEntity?.name) {
        this.configureTitles.contact_entity_name = selectedEntity.name;
        this.configureTitles.contact_entity_type = selectedEntity.entity_type;
      }
    },
    checkPosition(evt) {
      if (
        evt &&
        evt?.draggedContext &&
        typeof evt.draggedContext.futureIndex == "number" &&
        typeof evt.draggedContext.index == "number"
      ) {
        let currentStep = this.formBuilder.steps[evt.draggedContext.index];
        if (
          currentStep?.type == "TEMPLATE" &&
          currentStep?.settings?.is_repeatable &&
          currentStep?.settings?.linked_field &&
          currentStep.settings.linked_field.includes("#")
        ) {
          let index = parseInt(currentStep.settings.linked_field.split("#")[0]);
          if (index >= evt.draggedContext.futureIndex) {
            this.checkSwapAlert();
            return false;
          }
        }
        if (
          currentStep?.type == "TEMPLATE" &&
          currentStep?.settings?.is_repeatable &&
          currentStep?.settings?.linked_min_field &&
          currentStep.settings.linked_min_field.includes("#")
        ) {
          let index = parseInt(
            currentStep.settings.linked_min_field.split("#")[0]
          );
          if (index >= evt.draggedContext.futureIndex) {
            this.checkSwapAlert();
            return false;
          }
        }
      }
      return true;
    },
    checkSwapAlert() {
      this.$confirm(
        "This step is repeatable & configured with previous step.",
        "Warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Close",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    getTemplateFields(id, name, index, type) {
      const currentTemplate = (this.allTemplatesData || []).find(
        (e) => e._id == id
      );
      let result = [];
      if (currentTemplate?.sections[0]?.fields) {
        let tempName = name ? name : currentTemplate.name;
        currentTemplate?.sections[0]?.fields.forEach((fl) => {
          if (fl.inputType == "NUMBER") {
            result.push({
              label:
                parseInt(index) +
                1 +
                ". " +
                (type == "ENTITY" ? name + "-" : "") +
                tempName +
                "-" +
                fl.label,
              field: fl.key,
              key: index + "#" + currentTemplate._id + "#" + fl.key,
            });
          }
        });
      }
      return result;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone == "Asia/Calcutta") {
        this.formBuilder.settings.schedules.time_zone = "Asia/Kolkata";
      } else {
        this.formBuilder.settings.schedules.time_zone = timezone;
      }

      return timezone + ", UTC" + timezoneoffset;
    },

    checkMonths() {
      if (minutesArray == 0) {
        for (let i = 0; i <= 59; i++) {
          minutesArray.push(i);
        }
      }
    },
    checkHours() {
      if (hoursArray == 0) {
        for (let i = 0; i <= 23; i++) {
          hoursArray.push(i);
        }
      }
    },

    checkDays() {
      if (daysArray == 0) {
        for (let i = 1; i <= 31; i++) {
          daysArray.push(i);
        }
      }
    },

    checkYears() {
      if (yearsArray == 0) {
        for (let i = 2022; i <= 2030; i++) {
          yearsArray.push(i);
        }
      }
    },

    checkTemplateExists(templateId) {
      if (this.currentEntity && this.currentEntity.templates) {
        let isExist = this.currentEntity.templates.find(
          (e) => e.template_id == templateId
        );
        if (isExist) {
          return true;
        }
        return false;
      }
      return false;
    },
    checkRelationship(entityId) {
      if (entityId !== this.formBuilder.entity_id) {
        return this.relationshipsData.find(
          (e) => e.child_entity_id == entityId || e.parent_entity_id == entityId
        );
      }
      return false;
    },

    handleCheckedHourChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAllhours =
        checkedCount === this.hours.length;
      this.ishoursIndeterminate =
        checkedCount > 0 && checkedCount < this.hours.length;
      this.formBuilder.settings.schedules.hours = value;
    },
    handleCheckedYearChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAllyears =
        checkedCount === this.years.length;
      this.isyearsIndeterminate =
        checkedCount > 0 && checkedCount < this.years.length;
      this.formBuilder.settings.schedules.years = value;
    },
    handleCheckYearsChange(val) {
      this.formBuilder.settings.schedules.years = val ? yearsArray : [];
      this.isyearsIndeterminate = false;
    },
    handleCheckedMonthChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAllmonths =
        checkedCount === this.months.length;
      this.ismonthsIndeterminate =
        checkedCount > 0 && checkedCount < this.months.length;
      this.formBuilder.settings.schedules.months = value;
    },
    handleCheckMonthsChange(val) {
      this.formBuilder.settings.schedules.months = val ? monthsArray : [];
      this.ismonthsIndeterminate = false;
    },
    handleCheckedWeekChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAllweeks =
        checkedCount === this.weeks.length;
      this.isweeksIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
      this.formBuilder.settings.schedules.days_of_week = value;
    },
    handleCheckWeeksChange(val) {
      this.formBuilder.settings.schedules.days_of_week = val ? weeksArray : [];
      this.isweeksIndeterminate = false;
    },
    handleCheckedDayChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAlldays =
        checkedCount === this.days.length;
      this.isdaysIndeterminate =
        checkedCount > 0 && checkedCount < this.days.length;
      this.formBuilder.settings.schedules.days = value;
    },
    handleCheckDaysChange(val) {
      this.formBuilder.settings.schedules.days = val ? daysArray : [];
      this.isdaysIndeterminate = false;
    },
    handleCheckHoursChange(val) {
      this.formBuilder.settings.schedules.hours = val ? hoursArray : [];
      this.ishoursIndeterminate = false;
    },
    handleCheckMinutesChange(val) {
      this.formBuilder.settings.schedules.minutes = val ? minutesArray : [];
      this.isminutesIndeterminate = false;
    },
    handleCheckedMinuteChange(value) {
      let checkedCount = value.length;
      this.formBuilder.settings.schedules.checkAllminutes =
        checkedCount === this.minutes.length;
      this.isminutesIndeterminate =
        checkedCount > 0 && checkedCount < this.minutes.length;
      this.formBuilder.settings.schedules.minutes = value;
    },
    resetConfigureSettings() {
      this.configureTitles = {
        sender_title: "",
        receiver_title: "",
        isLoginEntity: false,
        applyRelationship: false,
        linkedToUserTemplate: false,
        selectedTemplates: [],
        is_repeatable: false,
        max_records: 3,
        min_records: 1,
        linked_field: "",
        linked_min_field: "",
        contact_entity: "",
        contact_entity_name: "",
        contact_entity_type: "",
        entity_table_configuration: [],
      };
      this.entity_table_configuration = [];
      this.configureSettings = false;
      this.entityTables = [];
    },
    doneTitleConfigure() {
      if (this.entity_table_configuration) {
        this.configureTitles.entity_table_configuration =
          this.entity_table_configuration;
      }
      this.formBuilder.steps[this.selectedStepIndex].settings = {
        ...this.configureTitles,
      };
      this.configureTitles = {
        sender_title: "",
        receiver_title: "",
        isLoginEntity: false,
        applyRelationship: false,
        linkedToUserTemplate: false,
        is_repeatable: false,
        max_records: 3,
        min_records: 1,
        linked_field: "",
        linked_min_field: "",
        contact_entity: "",
        contact_entity_name: "",
        contact_entity_type: "",
        entity_table_configuration: [],
      };
      this.selectedStepIndex = -1;
      this.configureStepTitlesVisible = false;
      this.configureSettings = false;
      this.entityTables = [];
      this.entity_table_configuration = [];
    },
    async updateStepData(step, index) {
      this.seletedStepData = { ...{}, ...JSON.parse(JSON.stringify(step)) };
      this.selectedStepIndex = index;
      this.configureSettings = true;
      this.entityTables = [];
      this.configureTitles = {
        sender_title: "",
        receiver_title: "",
        isLoginEntity: false,
        applyRelationship: false,
        linkedToUserTemplate: false,
        is_repeatable: false,
        max_records: 3,
        min_records: 1,
        linked_field: "",
        linked_min_field: "",
        selectedTemplates: [],
        contact_entity: "",
        contact_entity_name: "",
        contact_entity_type: "",
        entity_table_configuration: [],
      };
      if (this.seletedStepData?.type == "TEMPLATE") {
        let selectedTemplate = this.allTemplatesData.find(
          (e) => e._id == this.seletedStepData.template
        );
        let fields = this.getFieldsFromTemplate([selectedTemplate]);
        if (fields && fields.length) {
          this.entityTables = fields.filter(
            (e) =>
              e.input_type == "ENTITY_TABLE" &&
              e.child_table_type == "interactive"
          );
        }
      }
      if (this.seletedStepData.type == "ENTITY") {
        this.entityTemplates = [];
        this.entityTemplates = this.getEntityTemplates(
          this.seletedStepData.entity
        );
      }
      if (step.settings) {
        let data = { ...step.settings };
        if (
          data.isLoginEntity &&
          data.selectedTemplates &&
          data.selectedTemplates.length
        ) {
          let entityTemplates = this.entityTemplates.flatMap((e) => e._id);
          data.selectedTemplates = data.selectedTemplates.filter(
            (dt) => entityTemplates.indexOf(dt) !== -1
          );
        }
        this.configureTitles = JSON.parse(JSON.stringify(data));
        this.entity_table_configuration =
          JSON.parse(JSON.stringify(data)).entity_table_configuration || [];
      }
    },
    backToStepOne() {
      if (this.activeStep == 1 || this.activeStep == 2) {
        this.activeStep = 0;
      }
    },
    backToStepTwo() {
      if (
        ((this.activeStep == 2 || this.activeStep == 0) &&
          this.formBuilder.name &&
          this.formBuilder.form_builders_owner &&
          this.formBuilder.form_builders_owner == "ENTITY" &&
          this.formBuilder.entity_id) ||
        (this.formBuilder.form_builders_owner == "USER" &&
          this.formBuilder.user_type)
      ) {
        this.activeStep = 1;
      }
    },
    backToStepThree() {
      if (this.activeStep == 1) {
        if (!this.formBuilder.steps || !this.formBuilder.steps.length) {
          this.$message.warning(
            `Please add at least one form template or entity.`
          );
          return;
        }
      }
      if (
        (this.activeStep == 1 || this.activeStep == 0) &&
        this.formBuilder.name &&
        this.formBuilder.form_builders_owner &&
        this.formBuilder.steps &&
        this.formBuilder.steps.length
      ) {
        this.activeStep = 2;
        this.allFormbuilderFields = this.getAllFormbuilderFields([
          "SINGLE_LINE_TEXT",
          "PHONE_COUNTRY_CODE",
          "ENTITY",
        ]);
      }
    },
    uploadLogo() {
      if (!this.files) {
        this.$message.error("Please select a file.");
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024;
      if (this.files.size > maxSizeInBytes) {
        this.$message.error("File size should be less than 5MB.");
        return;
      }

      let reader = new FileReader();
      let that = this;
      reader.onloadend = function () {
        that.formBuilder.logo = reader.result;
      };
      if (this.files instanceof Blob) {
        reader.readAsDataURL(this.files);
      } else {
        this.$message.error("Invalid file.");
        return;
      }
      this.logoUploadModal = false;
    },
    openLogoModal() {
      this.logoUploadModal = true;
    },
    closeLogoModal() {
      this.logoUploadModal = false;
    },
    closeIconModel() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.formbuilderIconPath = icon;
      this.iconPickerdialogVisible = false;
    },
    templateIcons() {
      if (!this.templates) {
        return;
      }
      const data = {};
      this.templates.forEach(
        (template) => (data[template._id] = template.icon)
      );
      return data;
    },
    assetAlreadySeleted(asset) {
      if (
        asset.value != "COMPANY_DOCUMENTS" &&
        asset.value != "RELATIONAL_DATA"
      ) {
        return (
          this.formBuilder.steps.findIndex((x) => x.type == asset.value) > -1
        );
      }
      return false;
    },
    entityAlreadySelected(entity) {
      return (
        this.formBuilder.steps.findIndex(
          (x) => x.type === "ENTITY" && x.entity == entity._id
        ) > -1
      );
    },
    searchTemplates() {
      if (this.templateSearchString.length > 0) {
        const regex = new RegExp(this.templateSearchString, "i");
        const matchedTemplates = (this.allTemplatesData || []).filter(
          ({ name }) => name.match(regex)
        );
        this.templatesData = [...[], ...matchedTemplates];
      } else {
        this.templatesData = [...[], ...(this.allTemplatesData || [])];
      }
    },
    searchEntities() {
      if (this.entitySearchString.length > 0) {
        const regex = new RegExp(this.entitySearchString, "i");
        const matchedTemplates = (this.allEntitiesData || []).filter(
          ({ name, type }) => name.match(regex) && type !== "STANDARD"
        );
        this.entitiesData = [...[], ...matchedTemplates];
      } else {
        this.entitiesData = [...[], ...(this.allEntitiesData || [])];
      }
    },
    clickEntity(entity, type) {
      if (this.entityAlreadySelected(entity)) {
        return;
      }
      this.formBuilder.steps.push({
        entity: entity._id,
        type,
        slug: "entity_" + (entity.slug ? entity.slug : makeSlug(entity.name)),
        settings: {
          ...this.configureTitles,
          ...{
            selectedTemplates: this.getEntityTemplates(entity._id).flatMap(
              (e) => e._id
            ),
            applyRelationship:
              this.formBuilder.form_builders_owner == "ENTITY" &&
              this.checkRelationship(entity._id)
                ? true
                : false,
            isLoginEntity:
              this.formBuilder.form_builders_owner == "ENTITY" &&
              entity._id == this.formBuilder.entity_id
                ? true
                : false,
          },
        },
      });
      // this.currentEntity = null;
    },
    //getting entity templates by entity id
    getEntityTemplates(id) {
      let result = [];
      if (id) {
        let stepEntity = (this.allEntitiesData || []).find((e) => e._id == id);
        if (stepEntity?.templates) {
          let templates = stepEntity?.templates.flatMap((e) => e.template_id);
          result = (this.allTemplatesData || []).filter(
            (te) => templates.indexOf(te._id) != -1
          );
        }
      }
      return result;
    },
    clickTemplate(template, type) {
      if (this.templateAlreadySeleted(template)) {
        return;
      }
      let obj = {
        contact_entity: "",
        contact_entity_name: "",
        contact_entity_type: "",
      };
      let standardEntity = (this.allEntitiesData || []).find(
        (e) => e.type == "STANDARD"
      );
      obj.contact_entity =
        this.formBuilder.form_builders_owner == "ENTITY" &&
        this.formBuilder.entity_id
          ? this.formBuilder.entity_id
          : standardEntity?._id
          ? standardEntity._id
          : "";
      if (obj.contact_entity) {
        let selectedEntityData = (this.allEntitiesData || []).find(
          (e) => e._id == obj.contact_entity
        );
        obj.contact_entity_name = selectedEntityData.name;
        obj.contact_entity_type = selectedEntityData.entity_type;
      }
      if (type === "TEMPLATE" && template.type == "STANDARD") {
        let receiverEntity = this.allEntitiesData.find(
          (entity) => entity.name === "Receiver"
        );
        if (receiverEntity) {
          obj.contact_entity = receiverEntity._id;
          obj.contact_entity_name = receiverEntity.name;
          obj.contact_entity_type = receiverEntity.entity_type;
        }
      } else {
        obj.contact_entity = this.allEntitiesData[0]._id;
        obj.contact_entity_name = this.allEntitiesData[0].name;
        obj.contact_entity_type = this.allEntitiesData[0].entity_type;
      }
      this.formBuilder.steps.push({
        template: template._id,
        type,
        slug: template.slug,
        standard_template: template.type == "STANDARD" ? true : false,
        settings: {
          is_repeatable: template.type == "STANDARD" ? true : false,
          max_records: 3,
          min_records: 1,
          ...obj,
        },
      });
      this.currentTemplate = null;
    },
    templateAlreadySeleted(template) {
      return (
        this.formBuilder.steps.findIndex(
          (x) =>
            (x.type === "TEMPLATE" && x.template == template._id) ||
            (x.type === "RELATIONAL_DATA" &&
              x.relational_data.template == template._id)
        ) > -1
      );
    },
    onChangeFormBuilderStep(action) {
      this.checkMailRecievers();
      if (this.activeStep == 1) {
        if (!this.formBuilder.steps || !this.formBuilder.steps.length) {
          this.$message.warning(
            `Please add at least one form template or entity.`
          );
          return;
        }
      }
      if (this.activeStep == 2) {
        let check = this.checkApprovalUsers();
        if (check) {
          return;
        }
      }
      if (action == "Form Builder Details") {
        this.activeStep = 0;
      }

      if (action == "Build Form Builder" && this.formBuilder.name) {
        this.activeStep = 1;
      }
      if (action == "Form Builder Settings") {
        this.activeStep = 2;
        this.allFormbuilderFields = this.getAllFormbuilderFields([
          "SINGLE_LINE_TEXT",
          "PHONE_COUNTRY_CODE",
          "ENTITY",
        ]);
      }
    },
    checkApprovalUsers() {
      if (this.formBuilder?.is_approval_form) {
        if (
          !this.formBuilder.approval_users.length ||
          !this.formBuilder.approval_users
        ) {
          this.$message.warning(`Please add at least one approver.`);
          return true;
        }
        let notConfigeredUser = this.formBuilder.approval_users.findIndex(
          (e) => {
            if (!e.user_type) {
              return true;
            }
            if (e.user_type == "APPLICATION") {
              return (
                !e.application_user_type ||
                (!e.relational_user && !e.preferred_application_users.length)
              );
            } else if (e.user_type == "COMPANY") {
              return !e.company_user_type || !e.preferred_company_users.length;
            } else if (e.user_type == "LINKEDFIELD") {
              return !e.approval_linked_field;
            }
          }
        );
        if (notConfigeredUser !== -1) {
          this.$message.warning(
            `Please configure ${notConfigeredUser + 1} user`
          );
          return true;
        }
      }
      return false;
    },
    async fetchFormBuilderData() {
      await this.$store.dispatch("formBuilders/fetchFormBuilder", {
        id: this.formBuilderId,
        include_document_details: true,
      });
      if (this.getFormBuilder) {
        let data = JSON.parse(JSON.stringify(this.getFormBuilder));
        this.form_builder = data;
        this.formBuilder.name = data.name;
        this.formBuilder.description = data.description;
        this.formBuilder.steps = data.steps;
        this.formBuilder.user_type = data.user_type;
        this.formBuilder.form_builders_owner = data.form_builders_owner;
        this.formBuilder.form_builder_filter = data.form_builder_filter;
        this.formBuilder.is_approval_form = data.is_approval_form;
        this.formBuilder.priority_based = data.priority_based;
        this.formBuilder.entity_id = data.entity_id;
        this.formBuilder.logo = data.logo;
        this.formBuilder.approval_users = data.approval_users;
        this.formBuilder.approval_receiver_permissions.view = data?.approval_receiver_permissions?.view ? data.approval_receiver_permissions.view : true;
        this.formBuilder.approval_receiver_permissions.edit = data?.approval_receiver_permissions?.edit? data.approval_receiver_permissions.edit : false;
        this.formBuilder.approval_receiver_permissions.delete = data?.approval_receiver_permissions?.delete? data.approval_receiver_permissions.edit : false;
        this.formBuilder.approve_all_user = data.approve_all_user;
        this.formBuilder.primary_fields = data.primary_fields || [];
        if (data.receiver_mail_template)
          this.formBuilder.receiver_mail_template = data.receiver_mail_template;
        if (data.approval_mail_template)
          this.formBuilder.approval_mail_template = data.approval_mail_template;
        if (data.customization) {
          this.formBuilder.customization = data.customization;
        }
        if (data.expiration_settings) {
          this.formBuilder.expiration_settings = data.expiration_settings;
        }
        let form_builder_owners = data.form_builder_owners;
        this.formBuilder.form_builder_owners = data.form_builder_owners.length
          ? data.form_builder_owners
          : [data.created_by];
        this.formBuilder.settings = {
          // ...this.default_settings,
          ...this.formBuilder.settings,
          ...data.settings,
          formbuilder_date_range: [
            data.settings.formbuilder_start_time,
            data.settings.formbuilder_end_time,
          ],
        };
        this.formBuilder.settings.success_and_redirect_page.mail_recievers =
          form_builder_owners.length
            ? data.settings.success_and_redirect_page.mail_recievers
            : [data.created_by];
        this.formBuilder.settings.schedules.entity_id = data.entity_id;
        this.formBuilder.groups = data.groups;
        this.formbuilderIconPath =
          data.icon && data.icon_type == "DEFAULT"
            ? data.icon
            : "block-portfolio.svg";

        this.iconType = data.icon_type || "DEFAULT";
        if (
          this.formBuilder.form_builders_owner == "ENTITY" &&
          this.formBuilder.entity_id
        ) {
          this.formBuilder.settings.allowed_entity_users = (
            data.settings.allowed_entity_users || []
          )
            .filter((e) => e.email && e.contact_id)
            .map((user) => {
              return user.contact_id + "#" + user.email;
            });
          this.fetchEntityData(this.formBuilder.entity_id);
        }
        if (
          !data?.settings?.is_time_bond &&
          !data?.settings?.formbuilder_date_range
        ) {
          this.formBuilder.settings.formbuilder_date_range = [
            new Date(),
            new Date(),
          ];
        }
        this.allFormbuilderFields = this.getAllFormbuilderFields([
          "SINGLE_LINE_TEXT",
          "PHONE_COUNTRY_CODE",
          "ENTITY",
        ]);
      }
    },
    async fetchEntityData(entity_id) {
      await Promise.all([
        this.$store.dispatch("entities/fetchEntityById", {
          entity_id: entity_id,
        }),
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: entity_id,
        }),
        this.$store.dispatch("contacts/fetchAllContacts", {
          contact_type: entity_id,
          get_all: true,
          type: "CONTACT",
        }),
        this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: entity_id,
        }),
      ]);
      if (this.getAllEntityFilters) {
        this.entityFilters = this.getAllEntityFilters;
      }
      this.relationshipsData = [];
      this.entityTemplates = [];
      this.entityUsers = [];
      if (this.getEntityRelationships) {
        this.relationshipsData = [...(this.getEntityRelationships || [])];
      }
      this.currentEntity = null;
      if (this.getEntityDataById) {
        this.currentEntity = { ...{}, ...this.getEntityDataById };
        this.currentEntity.templates.forEach((temp) => {
          this.entityTemplates.push(temp.templateInfo);
        });
      }
      if (this.getAllContacts?.data) {
        this.entityUsers = this.getAllContacts.data;
      }
    },
    setStandardUser(value) {
      this.setFormbuilderData();
      (this.formBuilder.steps || []).map((step) => {
        if (
          step?.standard_template &&
          step?.settings?.contact_entity !== value &&
          step?.settings?.contact_entity_type == "BUSINESS"
        ) {
          let receiverEntity = this.allEntitiesData.find(
            (entity) => entity.name === "Receiver"
          );
          let obj = step.settings;
          if (receiverEntity) {
            obj.contact_entity = receiverEntity._id;
            obj.contact_entity_name = receiverEntity.name;
            obj.contact_entity_type = receiverEntity.entity_type;
          }
          step.settings = { ...obj };
        }
        return step;
      });
    },
    setSettingsEntity(value) {
      this.formBuilder.settings.schedules.entity_id = value;
      this.setStandardUser(value);
      if (value) {
        this.fetchEntityData(value);
      }
    },
    async fetchFormBuilderRemindersSettings() {
      await this.$store.dispatch(
        "formBuilders/fetchFormBuilderRemindersSettings",
        {
          id: this.formBuilderId,
        }
      );

      if (
        this.getFormBuilderRemindersSettings &&
        this.getFormBuilderRemindersSettings.type
      ) {
        this.formBuilder.settings.reminder_settings = {
          ...this.getFormBuilderRemindersSettings,
        };
      } else {
        this.formBuilder.settings.reminder_settings = {
          type: "WORKFLOW",
          workflow_details_id: "",
          reminder_start_time: "",
          reminder_end_time: "",
          reminder_interval_type: "",
          reminder_interval_value: 0,
          status: false,
        };
      }
    },
    // if the approver is deleted, then remove the approver mail
    removeApprovalUsers() {
      let aMails = this.allContacts.map((e) => e.email);
      let cMails = this.company_users.map((e) => e.email);
      this.formBuilder.approval_users = (
        this.formBuilder.approval_users || []
      ).map((user) => {
        if (user?.preferred_company_users) {
          user.preferred_company_users = user.preferred_company_users.filter(
            (e) => cMails.includes(e)
          );
        } else if (user?.preferred_application_users) {
          user.preferred_application_users =
            user.preferred_application_users.filter((e) => aMails.includes(e));
        }
        return user;
      });
    },
    async getNecessaryInfo() {
      this.loading = true;
      if (this.$route.params.form_builder_id) {
        this.formBuilderId = this.$route.params.form_builder_id;
        this.action = "Update";
        await this.fetchFormBuilderData();
        await this.fetchFormBuilderRemindersSettings();
      }
      await Promise.all([
        this.fetchAllDependentData(),
        this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          type: "CONTACT_TYPE",
        }),
        this.fetchContacts(),
        this.getCompanyUsersList(),
      ]);
      if (this.getformbuildertemplateDatawithAi != null) {
        this.formBuilder = {
          ...this.formBuilders,
          ...this.getformbuildertemplateDatawithAi,
        };
        this.activeStep = 1;
        this.$store.commit(
          "formBuilders/setformbuildertemplateDatawithAi",
          null,
          { root: true }
        );
        if (this.$route.query.templateId) {
          let temp = this.allTemplatesData.find(
            (t) => t._id == this.$route.query.templateId
          );
          this.clickTemplate(temp, "TEMPLATE");
        }
      }
      this.removeApprovalUsers();
      // this.templatesData =
      //   this.getAllCompanyTemplatesData && this.allTemplatesData
      //     ? this.allTemplatesData.filter(e => e.type !== 'STANDARD')
      //     : [];
      // this.templatesData = [
      //   this.allTemplatesData.find(e => e.type === "STANDARD")
      // ].concat(
      //   this.allTemplatesData.filter(e => e.type !== "STANDARD")
      // );
      // if (this.allEntitiesData) {
      //   this.entitiesData = [];
      //   this.entitiesData = this.allEntitiesData.filter(
      //     e => e.type !== "STANDARD"
      //   );
      // }
      // await this.$store.dispatch("userTypes/fetchUserTypes", {
      //   get_all: true,
      // });

      // this.allUserTypes = (this.getUserTypes.data || []).filter(
      //   (userType) => userType.name != "ADMIN"
      // );

      this.loading = false;
    },
    async fetchAllDependentData() {
      this.loading = true;
      [this.allEntitiesData, this.allTemplatesData] = await Promise.all([
        this.fetchAllEntities(),
        this.fetchAllTemplates(),
      ]);
      this.templatesData = [...this.allTemplatesData];
      this.entitiesData = [...this.allEntitiesData];
    },
    backToAllFormBuilders() {
      this.$router.push({ path: "/formBuilders", query: this.$route.query });
    },
    disableSaveAndNextButton() {
      return (
        !this.formBuilder.name ||
        !this.formBuilder.form_builders_owner ||
        (this.formBuilder.form_builders_owner == "ENTITY" &&
          !this.formBuilder.entity_id) ||
        (this.formBuilder.form_builders_owner == "USER" &&
          !this.formBuilder.user_type) ||
        !this.formBuilder.form_builder_owners.length
      );
    },
    async goToNextStep() {
      await this.checkMailRecievers();
      this.setStandardUser(this.formBuilder.entity_id);
      if (this.activeStep == 2) {
        try {
          await this.saveFormBuilder();
        } catch (err) {
          console.log("goToNextStep", err);
        }
      } else if (this.activeStep == 1) {
        if (!this.formBuilder.steps || !this.formBuilder.steps.length) {
          this.$message.warning(
            `Please add at least one form template or entity.`
          );
          return;
        }
        this.formBuilderEditStep = "Form Builder Settings";
        this.activeStep++;
      } else {
        this.formBuilderEditStep = "Build Form Builder";
        this.activeStep++;
      }
    },
    checkMailTemplate(body) {
      let valid = true;
      const leftBracesCount = body.split("[[").length - 1;
      const rightBracesCount = body.split("]]").length - 1;
      if (leftBracesCount < rightBracesCount) {
        this.$message.warning("Please remove unwanted ]]");
        valid = false;
      } else if (leftBracesCount > rightBracesCount) {
        this.$message.warning("Please remove unwanted [[");
        valid = false;
      }
      const regex = /\[\[\s*]]/g;
      if (regex.test(body)) {
        this.$message.warning("There should be field inside [[ ]]");
        valid = false;
      }
      // Check for [[ before ]]
      if (/\[\[[^\]]*\[\[/.test(body)) {
        this.$message.warning("No [[ allowed inside double brackets");
        valid = false;
      }
      return valid;
    },
    validateMailTemplate() {
      let valid = true;
      if (this.formBuilder?.is_approval_form) {
        if (this.formBuilder?.approval_mail_template?.body) {
          valid = this.checkMailTemplate(
            this.formBuilder.approval_mail_template.body
          );
        }
      }
      if (
        this.formBuilder?.approval_users &&
        this.formBuilder.approval_users.length
      ) {
        let emptyUser = (this.formBuilder.approval_users || []).findIndex(
          (user) => {
            if (!user.user_type) {
              return true;
            }
            if (user.user_type == "COMPANY") {
              if (
                !user.company_user_type ||
                !user.preferred_company_users ||
                !user.preferred_company_users.length
              ) {
                return true;
              }
            } else if (user.user_type == "APPLICATION") {
              if (!user.application_user_type) {
                return true;
              }
              if (
                !user?.relational_user &&
                (!user.preferred_application_users ||
                  !user.preferred_application_users.length)
              ) {
                return true;
              }
            } else {
              if (!user.approval_linked_field) {
                return true;
              }
            }
          }
        );
        if (this.formBuilder?.is_approval_form && emptyUser > -1) {
          this.$message.warning(
            "Please config " + (emptyUser + 1) + " approver"
          );
          valid = false;
        }
      } else {
        if (this.formBuilder?.is_approval_form) {
          this.$message.warning("Please add atleast on approver");
          valid = false;
        }
      }
      if (this.formBuilder?.receiver_mail_template?.body && valid) {
        valid = this.checkMailTemplate(
          this.formBuilder.receiver_mail_template.body
        );
      }
      return valid;
    },
    async saveFormBuilder() {
      try {
        let valid = this.validateMailTemplate();
        if (this.formBuilder?.settings?.is_time_bond) {
          if (!this.formBuilder?.settings?.formbuilder_date_range) {
            this.$message.error("Please select date range");
            return;
          }
        }
        if (!valid) {
          return;
        }
        this.loading = true;
        const payload = this.prepareFormBuilderData();
        if (this.formBuilderId) {
          await this.updateFormBuilder(payload);
          // await this.updateReminderSettings();
        } else {
          await this.addFormBuilder(payload);
        }
        this.loading = false;
      } catch (err) {
        this.$message.error(
          `Error at ${
            this.formBuilderId ? "Updating" : "creating"
          } form builder`
        );
      }
    },
    async updateReminderSettings() {
      await this.$store.dispatch(
        "formBuilders/updateWorkFlowRemindersSettings",
        {
          id: this.formBuilderId,
          ...this.formBuilder.settings.reminder_settings,
        }
      );
    },
    removeExistedField(index) {
      let currentStep = this.formBuilder.steps[index];
      if (currentStep && currentStep.type == "TEMPLATE") {
        let isInvolved = this.formBuilder.steps.find(
          (e) =>
            e?.settings?.is_repeatable &&
            ((e?.settings?.linked_field &&
              e.settings.linked_field.includes("#") &&
              e.settings.linked_field.split("#")[1] == currentStep.template) ||
              (e?.settings?.linked_min_field &&
                e.settings.linked_min_field.includes("#") &&
                e.settings.linked_min_field.split("#")[1] ==
                  currentStep.template))
        );
        if (isInvolved) {
          this.$message({
            message:
              "This template is associated with other templates. Can't delete.",
            type: "warning",
          });
        } else {
          this.formBuilder.steps.splice(index, 1);
        }
      } else {
        this.formBuilder.steps.splice(index, 1);
      }
    },
    setFormbuilderData() {
      if (
        this.formBuilder.form_builders_owner == "COMPANY" ||
        this.formBuilder?.form_builders_owner == "ANONYMOUS"
      ) {
        this.formBuilder.user_type = undefined;
        this.formBuilder.entity_id = undefined;
      } else if (this.formBuilder.form_builders_owner == "ENTITY") {
        this.formBuilder.user_type = undefined;
      } else if (this.formBuilder.form_builders_owner == "USER") {
        this.formBuilder.entity_id = undefined;
      }
    },
    prepareFormBuilderData() {
      this.formBuilder.name = this.formBuilder.name.replaceAll("/", "_");
      this.formBuilder.code = getCodeByName(this.formBuilder.name);
      this.formBuilder.icon = this.formbuilderIconPath;
      this.formBuilder.icon_type = this.iconType;

      this.formBuilder.steps.map((step, index) => {
        if (step.type == "COMPANY_DOCUMENTS") {
          if (step.company_documents && step.company_documents.custom) {
            step.company_documents.custom = step.company_documents.custom.map(
              (e) => e._id
            );
          }
          if (step.company_documents && step.company_documents.static) {
            step.company_documents.static = step.company_documents.static.map(
              (e) => e._id
            );
          }
          if (step.company_documents && step.company_documents.requested) {
            step.company_documents.requested =
              step.company_documents.requested.map((e) => e._id);
          }
        }
        if (
          this.formBuilder.form_builders_owner !== "ENTITY" &&
          step.type == "ENTITY"
        ) {
          step.isLoginEntity = false;
          step.applyRelationship = false;
        }
        if (
          this.formBuilder.form_builders_owner == "ENTITY" &&
          this.formBuilder.entity_id &&
          step.entity != this.formBuilder.entity_id &&
          step.isLoginEntity
        ) {
          step.isLoginEntity = false;
        }
        step.order = index + 1;
      });

      if (this.formBuilderId) {
        this.formBuilder._id = this.formBuilderId;
      }
      this.setFormbuilderData();
      if (this.formBuilder.form_builder_filter == "") {
        delete this.formBuilder.form_builder_filter;
      }
      if (this.formBuilder?.settings?.allowed_entity_users.length) {
        this.formBuilder.settings.allowed_entity_users =
          this.formBuilder.settings.allowed_entity_users
            .filter((e) => e.includes("#"))
            .map((el) => {
              return {
                email: el.split("#")[1],
                contact_id: el.split("#")[0],
              };
            });
      }
      if (this.formBuilder.settings.reminder_settings) {
        this.formBuilder.reminder_time_interval_type =
          this.formBuilder.settings.reminder_settings.reminder_interval_type;
        this.formBuilder.reminder_time_interval_value =
          this.formBuilder.settings.reminder_settings.reminder_interval_value;
        this.formBuilder.reminder_status =
          this.formBuilder.settings.reminder_settings.status;
        this.formBuilder.reminder_start_time =
          this.formBuilder.settings.reminder_settings.start_time;
        this.formBuilder.reminder_end_time =
          this.formBuilder.settings.reminder_settings.end_time;
      }
      if (this.formBuilder?.receiver_mail_template?.body) {
        this.formBuilder.receiver_mail_template.body =
          this.mapMailTemplatevariables(
            this.formBuilder.receiver_mail_template.body
          );
      }
      if (this.formBuilder?.is_approval_form) {
        if (this.formBuilder?.approval_mail_template?.body) {
          this.formBuilder.approval_mail_template.body =
            this.mapMailTemplatevariables(
              this.formBuilder.approval_mail_template.body
            );
        }
        this.formBuilder.approval_users = this.formBuilder.approval_users.map(
          (a, i) => {
            if (
              i == this.formBuilder.approval_users.length - 1 &&
              this.formBuilder?.priority_based
            ) {
              a.final_approver = true;
            } else {
              a.final_approver = false;
            }
            a.order = i;
            return a;
          }
        );
      }
      return JSON.parse(JSON.stringify(this.formBuilder));
    },
    mapMailTemplatevariables(body, revert) {
      const childRegex = /\[\[(.*?)\]\]/g;
      const childVariables = [];
      let match;
      while ((match = childRegex.exec(body)) !== null) {
        childVariables.push(match[1]);
      }
      // Replace labels with their corresponding template_keys (need to change for other entities labels)
      body = body.replace(/\[\[(.*?)\]\]/g, (match, label) => {
        const template = this.allMentionableFields.find(
          (obj) =>
            (!revert && obj.label === label) || (revert && obj.id == label)
        );
        let value = "";
        if (revert) {
          value = template?.label;
        } else {
          value = template?.template_key;
        }
        if (template?.input_type == "PAY_BUTTON") {
          return template ? `[[${value}#pay]]` : match;
        }
        return template ? `[[${value}]]` : match;
      });
      return body;
    },
    async updateFormBuilder(payload) {
      if (
        this.formBuilder?.settings?.is_time_bond &&
        payload?.settings?.formbuilder_date_range
      ) {
        (payload.settings.formbuilder_start_time =
          payload.settings.formbuilder_date_range[0]),
          (payload.settings.formbuilder_end_time =
            payload.settings.formbuilder_date_range[1]);
      }
      await this.$store.dispatch("formBuilders/updateFormBuilder", payload);
      if (this.getFormBuilderUpdateStatus) {
        if (this.getFormBuilderUpdateData?.data) {
          await updateFormbuilderData(
            this.formBuilderId,
            this.getFormBuilderUpdateData.data
          );
        }
        this.$notify.success({
          title: "Success",
          message: "Form Builder Updated successfully",
        });

        setTimeout(() => {
          this.backToAllFormBuilders();
        }, 100);
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.errors && this.errors.critical_error
              ? this.errors.critical_errorder
              : "Error while saving Form Builder",
        });
      }
    },
    async showDetailedViewOfStep(step) {
      if (step.type == "TEMPLATE") {
        let template = this.allTemplatesData.find(
          (t) => t._id == step.template
        );
        this.previewTemplateName = `Preview of  " ${template.name} "`;
        this.detailedTempIds = [{ name: template.name, id: template._id }];
        this.activeTempIds = [template._id];
      } else if (step.type == "ENTITY") {
        let entity = this.allEntitiesData.find((e) => e._id == step.entity);
        this.detailedTempIds = entity.templates.map((tmp) => {
          return {
            name: this.allTemplatesData.find((t) => t._id == tmp.template_id)
              .name,
            id: tmp.template_id,
          };
        });
        this.activeTempIds = [this.detailedTempIds[0].id];
      }
      this.showDetailedView = true;
    },
    resetTemplateData() {
      this.templateId = null;
      this.templateData = [];
      this.entityId = null;
      this.showDetailedView = false;
    },

    async addFormBuilder(payload) {
      if (
        this.formBuilder?.settings?.is_time_bond &&
        payload?.settings?.formbuilder_date_range
      ) {
        (payload.settings.formbuilder_start_time =
          payload.settings.formbuilder_date_range[0]),
          (payload.settings.formbuilder_end_time =
            payload.settings.formbuilder_date_range[1]);
      }
      await this.$store.dispatch("formBuilders/addFormBuilder", payload);
      if (this.getFormBuilderAddStatus) {
        if (this.getFormBuilderAddData?.data) {
          await addSingleFormbuilderToLocal(this.getFormBuilderAddData.data);
        }
        this.$notify.success({
          title: "Success",
          message: "Form Builder Added successfully",
        });
        setTimeout(() => {
          this.backToAllFormBuilders();
        }, 100);
      } else {
        let message = this.getFormBuilderErrors
          ? this.getFormBuilderErrors.message
            ? this.getFormBuilderErrors.message
            : this.getFormBuilderErrors.errors &&
              this.getFormBuilderErrors.errors.details &&
              this.getFormBuilderErrors.errors.details[0] &&
              this.getFormBuilderErrors.errors.details[0].message
            ? this.getFormBuilderErrors.errors.details[0].message
            : "Error while saving Form Builder"
          : "Error while saving Form Builder";
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },
    async getCompanyUsersList() {
      try {
        await Promise.all([
          this.$store.dispatch(
            "auth/getCompanyUsersList",
            this.getAuthenticatedUser.current_workspace.company_id
          ),
          this.$store.dispatch("auth/getUserTypeList", {
            companyId: this.getAuthenticatedUser.current_workspace.company_id,
            isMenu: true,
          }),
        ]);
        if (this.getCompanyUsers) {
          this.company_users = this.getCompanyUsers.data;
          if (
            !this.company_users
              .flatMap((e) => e._id)
              .includes(this.getAuthenticatedUser._id)
          ) {
            this.company_users.push(this.getAuthenticatedUser);
          }
        }
        let owner = this.company_users.find(
          (e) => e._id == this.getAuthenticatedUser._id
        );
        if (
          this.formBuilder.form_builder_owners.length == 0 ||
          this.formBuilder.settings.success_and_redirect_page.mail_recievers
            .length == 0
        ) {
          this.formBuilder.form_builder_owners[0] = owner._id;
          this.formBuilder.settings.success_and_redirect_page.mail_recievers[0] =
            owner._id;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async checkMailRecievers() {
      try {
        this.formBuilder.settings.success_and_redirect_page.mail_recievers.forEach(
          (user) => {
            if (!this.formBuilder.form_builder_owners.includes(user)) {
              this.formBuilder.settings.success_and_redirect_page.mail_recievers.splice(
                this.formBuilder.settings.success_and_redirect_page.mail_recievers.indexOf(
                  user
                ),
                1
              );
            }
          }
        );
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async fetchCompanyGroups() {
      try {
        // await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
        //   get_all: true
        // });
        const groups = await fetchGroups({
          get_all: true,
        });
        if (groups?.data) {
          this.getAllEntityGroupsData = [...groups.data];
        }
      } catch (e) {
        console.log("fetchCompanyGroups", e);
      }
    },
    /*  ***Fetch Groups  end  **** */
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.groupName,
        description: "",
        entities: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        await addSingleGroupToLocal(this.getSingleEntityGroupData.data);
        this.fetchCompanyGroups();
        this.formBuilder.groups.push(this.getSingleEntityGroupData.data._id);
        this.loadingSaveGroup = false;
        this.groupName = "";
        this.showAddGroupDialog = false;
        this.templateGroups.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },
  },
  created() {
    bus.$on("date_range", (data) => {
      // if(data==='TODAY')
      // {
      //     const end = new Date()
      //     const start = new Date()
      //     this.formBuilder.settings.formbuilder_date_range=[start, end]
      // }
      if (data === "TOMARROW") {
        const end = new Date();
        const start = new Date();
        var newDate = moment(end).add(1, "days");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "WEEK") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(7, "days");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "BIWEEKLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(15, "days");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "MONTHLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "months");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "QUARTERLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(3, "months");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "HALFYEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(6, "months");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      if (data === "YEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "years");
        this.formBuilder.settings.formbuilder_date_range = [start, newDate];
      }
      const start = new Date();
      this.formBuilder.settings.formbuilder_date_range = [start, newDate];
    });
  },
};
</script>
<style lang="scss" scoped>
.settings-formBuilder {
  margin: 20px;
  margin-left: 0;
}
.fixed-col {
  position: fixed;
  top: 132px;
  right: 30px;
  width: 523px;
}
.icons-component {
  margin: 0px;
}
.logo-image-upload {
  margin: 20px;
}
.workflow-view {
  background-color: #f5f6ff;

  .pageTopBar {
    padding: 0.5em 1em;
    border: 1px solid #d8d8fc;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-height: 65px;
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
    }
  }

  .up-down-arrow {
    button {
      padding: 2px;
    }
  }

  .el-button.is-circle {
    padding: 8px;
  }

  .flip-list-move {
    transition: transform 1s;
  }

  .text-right {
    text-align: right;
    margin: 2em 0em;

    .el-button {
      padding: 0.75em 2em;
      font-size: 1.125em;
      text-align: center;
      border-radius: 30px !important;
    }
  }

  .workflow-details {
    position: relative;
    z-index: 20;
    height: 85vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    @media (max-width: 750px) {
      width: 78vw !important;
      position: relative;
      right: 4.5rem;
    }

    .label {
      font-size: 14px;
      color: #606266;
      line-height: 30px;
      padding: 0 12px 0 0;
    }

    .error {
      font-size: 14px;
      color: #df0e0e;
      margin-top: -10px !important;
    }
  }

  .inner-navbar {
    display: block;
    margin-bottom: 1em;
    height: 50px;

    h3 {
      font-weight: 400;
      font-size: 1.25em;
      line-height: 50px;
    }
  }

  // Work flow step
  .workflow-steps {
    #field-group {
      & > div:last-child {
        padding-bottom: 35px;
        position: relative;

        &:after {
          content: "END";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70px;
          display: block;
          margin: 0 auto;
          padding: 0.5em 1.5em;
          border-radius: 5px;
          border: 1px dashed #ff3a79;
          text-align: center;
          font-weight: 600;
          letter-spacing: 1px;
          color: #ff3a79;
        }
      }
    }

    position: relative;
    margin: 0 auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    max-height: calc(100vh - 115px);
    overflow-y: scroll;

    .el-button {
      span {
        font-size: 1.2em;
      }
    }

    .sub-heading {
      font-weight: 500;
      color: #1b487e;
      margin-bottom: 10px !important;
    }

    .each-step {
      cursor: pointer;
      position: relative;
      z-index: 10;
      display: block;
      max-width: 30em;
      background: #fdfdfd;
      border: 1px solid #a0a0a0;

      color: #1732f5;
      padding: 0;
      margin: 0 auto 3em;

      .inner-wrap {
        display: flex;
      }

      .delete-icon {
        padding: 4px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -3em;
        left: calc(50% - 1px);
        width: 2px;
        height: 3em;
        background-color: #1732f5;
      }

      .arrow-down {
        position: absolute;
        left: calc(50% - 8px);
        bottom: -3em;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #1732f5;
        z-index: 10;
      }

      .icon-template {
        border-right: 1px solid #ecf5ff;
        // background-color: #eaf2fd;
        border-radius: 9px 0 0 9px;
        min-width: 4.5em;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 2.25em;
        }
      }

      .content {
        padding: 0.85em 0 0.85em 1em;

        .main-title {
          font-weight: 500;
          font-size: 0.8em;
          color: #2f80ed;
          display: block;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }

        .sub-title {
          color: #000577;
          font-size: 1.15em;
        }
      }

      .action-action {
        min-width: 110px;
        display: flex;
        align-items: center;
        justify-items: flex-end;
        justify-content: flex-end;
        padding: 0.5em 0.25em 0.5em 0.75em;

        .template-box,
        .documents-box {
          display: flex;
          align-items: center;
          margin-right: 0.5em;

          .el-button {
            padding: 4px;
            margin-left: 0.5em;
          }

          .el-tag {
            padding: 0.4em 0.65em;
            line-height: 1;
            height: auto;
            border-radius: 1em;
          }
        }
      }
    }
  }
  .uploader {
    max-height: 70vh;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  // .el-form {
  //   min-height: calc(100vh - 81px);
  // }
}
</style>
<style lang="scss" scoped>
.block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.block:last-child {
  border-right: none;
}

.block .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.workflow-details {
  margin-top: 1em;

  .el-input__inner {
    line-height: 55px;
    border-radius: 10px;
    border-color: #cacaca;
  }

  .el-form-item__label {
    line-height: 30px;
  }

  .el-input-group--prepend .el-input-group__prepend {
    background-color: #eeeeff !important;
    border: 1px solid #cacaca !important;
    border-right: none !important;
    box-shadow: none !important;
    padding: 0;
    min-width: 80px;
    border-radius: 10px 0 0 10px;

    .el-button {
      display: block;
      margin: 0 auto;
      padding: 0;
    }

    .icon-dropdown-selection {
      margin-right: 0;

      i {
        color: #2f80ed;
      }
    }
  }
}

.select-steps-form {
  position: relative;
  background-color: #fafafa;
  padding: 1em 2em;
  border-radius: 0;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.03);
  border-right: 1px solid #f9f9f9;
  z-index: 10;
  min-height: calc(100vh - 100px);

  .each-widget {
    margin-bottom: 1em;

    .scrollable {
      max-height: 250px;
      overflow: auto;
      padding: 0 5px;
      box-shadow: 0 0 3px inset #efefef;

      .list-item {
        text-align: left;
        width: 100%;
        padding: 0.35em 0.5em;
        border-radius: 5px;
        background-color: #ffffff;
        border: 1px solid #e7e7e7;
        margin: 5px 0;
        color: #333333;
        cursor: pointer;

        &:hover {
          border-color: #1821d1;
          color: #000577;
          background-color: #f6f9ff;
        }

        &.is-disabled {
          background-color: #efefef;
          border-color: #e8e5e5;
          cursor: not-allowed;
        }

        & > span {
          gap: 10px;
          display: flex;
          align-items: center;
        }

        .text {
          font-size: 14px !important;
          margin-top: 7px !important;
        }
      }
    }
  }

  .divider {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    background-color: #f2f7fc;
    margin: 40px auto 0px;
  }

  .sub-heading {
    font-weight: 500;
    color: #333333;
  }
}

.workflow-view {
  .workflow-index {
    .el-step__title {
      line-height: 30px;
      font-size: 0.9em;
      letter-spacing: 0.5px;
    }

    .el-step__title.is-process {
      font-weight: 400 !important;
      color: #000577;
    }

    .el-step__head.is-wait,
    .el-step__title.is-wait {
      cursor: not-allowed;
    }

    .el-step.is-horizontal {
      .el-step__line {
        top: 18px !important;
        z-index: 0;
      }
    }

    .el-step__head {
      .el-step__icon {
        height: 40px;
        width: 40px;
        cursor: pointer;
      }

      &.is-process {
        color: #000577;
        font-weight: 400;

        .el-step__icon {
          border: 1px solid #000577;
        }
      }

      &.is-wait {
        .el-step__icon {
          cursor: not-allowed;
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  .template-editor {
    margin-top: 0px !important;
    border-color: #c0c4cc !important;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    margin-bottom: 1.5em;

    .menubar {
      border-radius: 9px 9px 0 0;
    }
  }
}

.settings-selection-dialog {
  height: 300px;
}

.document-selection-box {
  height: 300px;
}

.document-selection-dialog {
  .vue-data-table-default {
    margin-bottom: 0px;
  }

  .submit-block {
    padding-bottom: 1em;
    border-bottom: 1px solid #efefef;
    margin-bottom: 1em;
    text-align: center;

    #submitButton {
      padding: 0.5em 1em;
      font-size: 1.25em;
      min-width: 200px;
    }
  }

  .el-dialog {
    background-color: #f8f9fa;

    .el-dialog__body {
      max-height: calc(100vh - 115px);
    }
  }

  .el-input__inner:focus {
    border-color: #495df7;
    color: #1732f5;
  }

  .el-input__inner:hover {
    border-color: #7080f7;
  }

  .table-scrollable {
    border-left: 1px solid #e8f1fd;
    border-right: 1px solid #e8f1fd;
    margin-top: 1em;
    border-radius: 3px;
  }

  .dialog-sub-title {
    font-weight: 500;
    font-size: 1.25em;
  }

  .selected-docx {
    .scrollable-min {
      max-height: 22vh;
      overflow: auto;
      border: 1px solid #e8f1fd;
      margin-top: 1em;
      border-radius: 3px;

      .card-title {
        font-weight: 400;
        padding: 0.65em 1em;
        text-align: center;
      }

      .el-card__body {
        padding: 0;

        .each-document {
          padding: 0.65em 1em;
        }

        h4 {
          font-weight: 400;
        }

        .el-button {
          padding: 4px;
        }
      }
    }
  }

  .vue-data-table-default tbody tr td:last-child .cell .el-button {
    background-color: #409eff !important;
    color: #ffffff !important;

    &.is-disabled {
      opacity: 0.75;
    }
  }
}

.workflow-view {
  // Topbar Steps
  .steps-radio-group ::v-deep {
    .el-radio-button {
      .el-radio-button__inner {
        border-color: #dcdfe6 !important;
      }

      &:focus,
      &.is-active {
        box-shadow: none;

        .el-radio-button__inner {
          color: #fff !important;
          background-color: #1b487e !important;
          border-color: #1b487e !important;
          -webkit-box-shadow: -1px 0 0 0 #1b487e;
        }
      }
    }
  }
}

.ul1 {
  columns: 6;
}

.ul2 {
  columns: 3;
}

.ul3 {
  columns: 4;
}

.ul4 {
  columns: 2;
}

.bgcolor {
  background-color: #e1e4e8;
}

.ht {
  height: 500px;
}

.el-card {
  height: 800px !important;
}

.el-checkbox {
  margin-right: 20px !important;
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
  /* STYLES HERE */
  .el-card {
    height: 670px !important;
  }
}

.logo-edit {
  border: 1px solid #d8d8fc;
  text-align: center;

  img {
    margin-top: 3%;
  }

  .edit-box {
    text-align: right;
  }
}
@media (max-width: 998px) {
  .form-heading {
    font-size: 15px;
    font-weight: 500;
  }
}
@media (max-width: 750px) {
  .form-heading {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    right: 4rem;
    top: 2.5rem;
  }
}

.inner-box {
  width: 85%;
  height: 100%;
  background: #fff;
  border: 2px dashed #d2d2d2;
  padding: 15px;
  margin-left: 70px;
}

.try-with-ai {
  display: flex;
  align-items: center;
  width: 75%;
  margin-left: 50px;
  padding: 8px 18px;
  margin-bottom: 20px;
  border-radius: 35px;
  transition: all 0.3s ease;
  // border-color: var(--primary-color) !important;
}

.custom-icon-style {
  margin-left: 222px;
  color: #2f80ed;
  position: relative;
  top: 10px;
}
</style>
<style scoped>
.tooltip-content {
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
